import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { payoutStatusList } from "utilities/utility.service";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { add, getImagesCount, getSingle, update } from "../payouts.service";
import { Messages } from "primereact/messages";

const initialVales = {
    id: "",
    amount: 0,
    reference_number: null,
    role_type: null,
    user_id: null,
    status: null,
};

const AddPayouts = () => {
    const { id } = useParams();
    const msgs3 = useRef(null);
    const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));

    const [values, setValues] = useState(initialVales);
    const [imagesCount, setImagesCount] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef();
    const history = useHistory();
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    useEffect(() => {
        const fetchPayout = async () => {
            setIsLoading(true);
            const record = await getSingle(id);
            setValues(record);
            setIsLoading(false);
        };

        const fetchImagesCount = async () => {
            setIsLoading(true);
            const records = await getImagesCount(sessionInformation?.id, sessionInformation?.role_id);
            if (records) {
                setImagesCount(records);
                setValues({
                    ...values,
                    amount: records.length * 10,
                });
                msgs3.current.show({
                    severity: "warn",
                    sticky: true,
                    content: <React.Fragment>{`Minimum balance required is ${sessionInformation?.min_amount}. Your current balance is ${records?.totalImages ? records?.totalImages?.length * 10 : 0}`}</React.Fragment>,
                });
            }
            setIsLoading(false);
        };

        if (id) {
            fetchPayout();
        } else {
            if (sessionInformation?.role_id === 3) {
                setValues({
                    ...values,
                    role_type: "Reporter",
                });
            }
            if (sessionInformation?.role_id === 4) {
                setValues({
                    ...values,
                    role_type: "Processor",
                });
            }
            setValues({
                ...values,
                user_id: "sessionInformation?.id",
                status: "Requested",
            });
        }
        fetchImagesCount();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (id) {
            setIsLoading(true);
            const payload = { ...values, user_id: values?.user_id.toString() };
            const updatedRecord = await update(id, payload);
            setValues(updatedRecord);
            if (updatedRecord.status === false) {
                setIsLoading(false);
                showError(updatedRecord?.message);
            } else {
                setIsLoading(false);
                showSuccess("Payout request updated succssfully");
                history.push("/payouts");
            }
            // console.log(updatedRecord);
            // handleSnackBarAndRedirect(updatedRecord.message, updatedRecord.status);
        } else {
            if (Number(values?.amount) % 10 !== 0) {
                showError("Amount can be only entered in multiples of 10");
                return;
            }
            // else if (sessionInformation?.min_amount && Number(sessionInformation?.min_amount) > Number(values?.amount)) {
            //     showError("You cannot process the amount. Minimum balance required is " + sessionInformation?.min_amount);
            //     return;
            // }
            else if (Number(values?.amount) > imagesCount?.records?.length * 10) {
                showError("Amount entered is greater than Balance amount");
                return;
            } else {
                if (window.confirm("Are you sure you want to request for payout?")) {
                    let newImagesCount = Number(values?.amount) === imagesCount?.records?.length * 10 ? imagesCount?.records?.length : Number(values?.amount) / 10;

                    const payload = {
                        status: "Requested",
                        role_type: sessionInformation?.role_id === 3 ? "Reporter" : sessionInformation?.role_id === 4 ? "Processor" : null,
                        user_id: sessionInformation?.id.toString(),
                        reference_number: null,
                        amount: values?.amount,
                        imagesCount: newImagesCount,
                        imageIds: imagesCount?.records?.slice(0, newImagesCount).map((image) => {
                            return image.id;
                        }),
                    };
                    const addedRecord = await add(payload);
                    if (addedRecord.status === false) {
                        showError(addedRecord?.message);
                    } else {
                        showSuccess("Your payout request has been successfully created. Our team will get back to it soon.");
                        setTimeout(() => {
                            history.push("/payouts");
                        }, 2000);
                    }
                }
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const statuses = payoutStatusList();
    return (
        <Fragment>
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            <Toast ref={toast} className="ToastMessage" />
            <div className="mb-4">
                <Link to="/payouts">
                    <Button label="Back" className="p-button-sm p-button-success" />
                </Link>
            </div>
            <Messages ref={msgs3} />
            {!isLoading && (
                <form method="POST" onSubmit={handleSubmit} autoComplete="Off">
                    <div className="grid">
                        <div className="col-12 md:col-6">
                            <div className="card p-fluid">
                                <h5>{id ? "Requested Payout" : "Request Payout"}</h5>
                                <div className="field">
                                    <label htmlFor="processedCount">Balance Amount*</label>
                                    <InputText disabled id="balancedImages" name="" type="text" placeholder="Balance Amount" value={imagesCount?.records ? imagesCount?.records?.length * 10 : 0} />
                                </div>
                                {/* 
                            <div className="field">
                                <label htmlFor="processedCount">Amount To Withdraw*</label>
                                <InputText id="processedCount" name="" type="text" placeholder="Processed Count" value={values?.imagesCount} />
                            </div> */}

                                <div className="field">
                                    <label htmlFor="amount">Amount To Withdraw From Balance*</label>
                                    <InputText id="amount" disabled={sessionInformation?.role_id === 1} name="amount" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Amount" value={values?.amount && !isNaN(values?.amount) ? values?.amount : ""} />
                                </div>
                                {sessionInformation?.role_id === 1 ? (
                                    <Fragment>
                                        <div className="field">
                                            <label htmlFor="description">Reference Number*</label>
                                            <InputTextarea id="reference_number" name="reference_number" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Reference Number" value={values?.reference_number} required={true} />
                                        </div>

                                        <div className="field">
                                            <label htmlFor="status">Status*</label>
                                            <Dropdown id="status" name="status" value={values?.status} options={statuses} optionLabel="status" optionValue="status" placeholder="Select Status" required={true} onChange={(e) => handleInputChange(e)}></Dropdown>
                                        </div>
                                        <div>
                                            <Button label="Save" type="submit" />
                                        </div>
                                    </Fragment>
                                ) : (
                                    <div>
                                        <Button label="Request" type="submit" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Fragment>
    );
};

export default AddPayouts;
