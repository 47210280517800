import RestClient from "RestClient";

const restClient = new RestClient();

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const checkIfBatchAssigned = async (userId) => {
    const result = await restClient.get(`${BASE_URL}/batches/getUserAssignment/${userId}`, []);
    return result?.data;
};

export const fetchReportedData = async (batchId) => {
    const result = await restClient.get(`${BASE_URL}/reporteddata`, { batch_id: batchId });
    return result?.data;
};

export const postAnnotation = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/processeddata/create`, payload);
    // return result?.data;
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const announcementsList = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/announcements`, payload);
    return result?.data;
};
