import { fetchReportedData } from "pages/ProcessorRole/processorDashboard.service";
import { postAnnotation } from "pages/ProcessorRole/processorDashboard.service";
import { checkIfBatchAssigned } from "pages/ProcessorRole/processorDashboard.service";
import { getAllViolations } from "pages/Violations/violations.service";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { Component, Fragment } from "react";
import Annotation from "react-image-annotation-with-zoom";
import { add as AddBatch } from "pages/Batches/batches.service";

function renderContent({ annotation }) {
    const { geometry } = annotation;
    return (
        <div
            key={annotation.data.id}
            style={{
                background: "black",
                color: "white",
                padding: 10,
                position: "absolute",
                fontSize: 12,
                left: `${geometry.x}%`,
                top: `${geometry.y + geometry.height}%`,
            }}
        >
            <div> {annotation.data && annotation.data.text}</div>
            {annotation.data && annotation.data.vehicleNumber}
        </div>
    );
}

function renderEditor(props) {
    const { geometry, violationList } = props.annotation;

    if (!geometry) return null;
    let left = 0;
    let top = 0;

    left = document.querySelector("#annotedDiv").getBoundingClientRect().left + 30;
    top = document.querySelector("#annotedDiv").getBoundingClientRect().top + 30;
    return (
        <div
            style={{
                background: "white",
                borderRadius: 3,
                position: "fixed",
                left: `${left}px`,
                top: `${top}px`,
                padding: "1rem",
            }}
        >
            <div>Annotate </div>
            <div className="flex mb-2 mt-2">
                <Dropdown
                    placeholder="Select Violation"
                    onChange={(e) =>
                        props.onChange({
                            ...props.annotation,
                            data: {
                                ...props.annotation.data,
                                text: e.target.value,
                            },
                        })
                    }
                    optionLabel="name"
                    optionValue="name"
                    options={violationList}
                    className="mr-2"
                    value={props.annotation && props.annotation.data && props.annotation.data.text}
                />
                <InputText
                    placeholder="Vehicle Number"
                    onChange={(e) =>
                        props.onChange({
                            ...props.annotation,
                            data: {
                                ...props.annotation.data,
                                vehicleNumber: e.target.value,
                            },
                        })
                    }
                />
            </div>
            <Button disabled={!props?.annotation?.data} onClick={props.onSubmit} label="Submit" />
        </div>
    );
}

export default class Annotated extends Component {
    constructor(props) {
        super(props);
        this.state = {
            annotations: [],
            annotation: {
                violationList: [],
            },
            violationList: [],
            reportedList: [],
            currentIndex: 0,
            currentRecord: {},
            displayBasic2: false,
            displayModal: false,
            displayMaximizable: false,
            displayPosition: false,
            displayResponsive: false,
            position: "center",
            supportedImages: [],
        };

        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.fetchViolations();
        // this.handleKeyDown = this.handleKeyDown.bind(this);
        document.addEventListener("keydown", this._handleKeyDown);
    }

    onClick(name, position) {
        let state = {
            [`${name}`]: true,
        };

        if (position) {
            state = {
                ...state,
                position,
            };
        }

        this.setState(state);
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false,
        });
    }

    _handleKeyDown = (e) => {
        const { currentIndex, reportedList } = this.state;
        if (e.keyCode === 37) {
            if (currentIndex !== 0) {
                this.handlePrevious();
            }
        } else if (e.keyCode === 39) {
            if (currentIndex !== reportedList.length - 1) {
                this.handleNext();
            }
        }
        // arrow up/down button should select next/previous list element
        // if (e.keyCode === 38 && cursor > 0) {
        //   this.setState( prevState => ({
        //     cursor: prevState.cursor - 1
        //   }))
        // } else if (e.keyCode === 40 && cursor < result.length - 1) {
        //   this.setState( prevState => ({
        //     cursor: prevState.cursor + 1
        //   }))
        // }
    };

    fetchViolations = async () => {
        const violationList = await getAllViolations();
        if (violationList) {
            if (violationList) {
                this.setState({ violationList: violationList });
                // const annotationCopy = { ...this.state.annotation };
                // annotationCopy.violationList = violationList;
                // this.setState({ annotation: annotationCopy });
            }
        }
    };

    sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));

    componentDidMount = () => {
        const fetchAssignedBatch = async () => {
            const isBatchAssigned = await checkIfBatchAssigned(this.sessionInformation?.id);
            if (isBatchAssigned) {
                if (!isBatchAssigned?.accepted_flag && isBatchAssigned?.status === "Acceptance Pending") {
                    // history.push("/");
                } else if (isBatchAssigned?.accepted_flag && isBatchAssigned?.status === "Accepted") {
                    // Fetch Reported Data
                    // if (!isBatchAssigned || isBatchAssigned?.status === "Completed") {
                    //     setIsMessage(true);
                    // } else {
                    const fetchReported = await fetchReportedData(isBatchAssigned?.id);
                    // setRecords(fetchReported);
                    this.setState({
                        reportedList: fetchReported,
                    });
                    if (this.state.reportedList) {
                        this.setState({ currentRecord: this.state.reportedList[0] });
                    }
                    // }
                } else {
                    // alert("No Batch assigned to you");
                }
            } else {
                // alert("No Batch assigned to you");
                // this.props.history.push("/");
            }
        };
        fetchAssignedBatch();
    };

    onChange = (annotation) => {
        const annotationCopy = { ...annotation };
        annotationCopy.violationList = this.state.violationList;
        this.setState({ annotation: annotationCopy });
        // this.setState({ annotation });
    };

    getViolationId = (violationRecord) => {
        const record = this.state.violationList.find((violation) => violation.name === violationRecord);
        if (record) {
            return record;
        }
        return null;
    };

    onOpenSupportedImages = async (annotation) => {
        const { geometry, data } = annotation;
        const currentRecord = this.state.reportedList[this.state.currentIndex];
        let annotationData = [
            {
                geometry,
                data: {
                    ...data,
                    id: Math.random(),
                    image: currentRecord.image,
                    imagePath: currentRecord.imagePath,
                },
            },
        ];
        this.setState({
            annotation: {},
            // annotations: this.state.annotations.concat({
            //     geometry,
            //     data: {
            //         ...data,
            //         id: Math.random(),
            //     },
            // }),
            annotations: annotationData,
            displayBasic2: true,
        });

        // let payload = {
        //     batch_id: currentRecord?.batch_id,
        //     event_id: currentRecord?.event_id,
        //     reported_data_id: currentRecord?.id,
        //     violation_id: this.getViolationId(annotationData[0]?.data?.text),
        //     vehicle_number: annotationData[0]?.data?.vehicleNumber,
        //     annoted_data: annotationData[0],
        //     line_number: 1,
        //     processor_id: this.sessionInformation.id,
        // };
        // // console.log("payload",payload);
        // const addedRecord = await postAnnotation(payload);
        // if (addedRecord.status === false) {
        //     alert(addedRecord?.message);
        // } else {
        //     alert("Annoted Image Saved Successfully");
        //     this.setState({
        //         annotation: {},
        //         // annotations: this.state.annotations.concat({
        //         //     geometry,
        //         //     data: {
        //         //         ...data,
        //         //         id: Math.random(),
        //         //     },
        //         // }),
        //         annotations: [],
        //     });
        //     // window.location.reload();
        // }
    };

    onSubmit = async () => {
        const currentRecord = this.state.reportedList[this.state.currentIndex];
        const { annotations } = this.state;
        this.setState({
            displayBasic2: false,
        });
        const violationRecord = this.getViolationId(annotations[0]?.data?.text);
        let payload = {
            batch_id: currentRecord?.batch_id,
            event_id: currentRecord?.event_id,
            reported_data_id: currentRecord?.id,
            reporter_id: currentRecord?.reporter_id,
            violation_id: violationRecord?.id,
            vehicle_number: annotations[0]?.data?.vehicleNumber,
            annoted_data: annotations[0],
            image: annotations[0]?.data?.image,
            line_number: 1,
            processor_id: this.sessionInformation.id,
            supportedImages: this.state.supportedImages ? this.state.supportedImages.toString() : null,
            calculated_amount: violationRecord?.penalty_charges,
        };
        const addedRecord = await postAnnotation(payload);
        if (addedRecord.status === false) {
            alert(addedRecord?.message);
        } else {
            alert("Annoted Image Saved Successfully");
            this.setState({
                annotation: {},
                // annotations: this.state.annotations.concat({
                //     geometry,
                //     data: {
                //         ...data,
                //         id: Math.random(),
                //     },
                // }),
                displayBasic2: false,
                annotations: [],
                supportedImages: [],
            });
            // window.location.reload();
        }
    };

    handlePrevious = () => {
        const { currentIndex, reportedList } = this.state;
        let index = currentIndex;
        this.setState({ currentIndex: index - 1, currentRecord: reportedList[index - 1] });
    };

    handleNext = () => {
        const { annotations, currentIndex, reportedList } = this.state;
        this.setState({
            annotation: {},
            // annotations: this.state.annotations.concat({
            //     geometry,
            //     data: {
            //         ...data,
            //         id: Math.random(),
            //     },
            // }),
            displayBasic2: false,
            annotations: [],
            supportedImages: [],
        });
        if (annotations[0]?.data && annotations[0]?.data?.text !== "") {
            if (window.confirm("All the changes will be lost")) {
                // setIData([convertToIDATAObject(records[currentIndex + 1])]);
                let index = currentIndex;
                this.setState({ currentIndex: index + 1, currentRecord: reportedList[index + 1] });
            }
        } else {
            // setIData([convertToIDATAObject(records[currentIndex + 1])]);
            let index = currentIndex;
            this.setState({ currentIndex: index + 1, currentRecord: reportedList[index + 1] });
            // setCurrentIndex(index + 1);
        }
    };

    fetchProcessingImages = () => {};

    markAllAsProcessing = () => {};

    markAllAsProcessed = () => {};

    renderFooter(name) {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text" />
                <Button label="Processed" icon="pi pi-check" onClick={() => this.onSubmit()} autoFocus />
            </div>
        );
    }

    onChangeCheckBox = (e) => {
        const { value } = e;
        let supportedImagesCopy = [...this.state.supportedImages];
        // check if already exists
        const findIndex = supportedImagesCopy.findIndex((si) => si === value);
        if (findIndex === -1) {
            supportedImagesCopy.push(value);
            this.setState((prevState) => ({
                ...prevState,
                supportedImages: supportedImagesCopy,
            }));
        } else {
            supportedImagesCopy.splice(findIndex, 1);
            this.setState((prevState) => ({
                ...prevState,
                supportedImages: supportedImagesCopy,
            }));
        }
    };

    handleQAReview = (batchId) => {
        if (window.confirm("Are you sure your work is completed?")) {
            this.props.history.push(`/batches/processedImages/${batchId}`);
        }
    };

    renderSupportedImages = () => {
        const { reportedList, supportedImages } = this.state;
        return (
            <Dialog header="Add Supportive Images" visible={this.state.displayBasic2} footer={this.renderFooter("displayBasic2")} onHide={() => this.onHide("displayBasic2")}>
                <div className="grid">
                    {reportedList &&
                        reportedList.length > 0 &&
                        reportedList.map((list) => {
                            return (
                                <div className="col-12 md:col-4">
                                    <div className="flex justify-content-center align-items-center zoomWrapper">
                                        <Checkbox onChange={this.onChangeCheckBox} value={list.image} checked={supportedImages && supportedImages.some((si) => si === list.image)} />
                                        {/* <img className="ml-3 zoomOnHover" src={list?.imagePath} style={{ height: "150px", width: "100%" }} /> */}

                                        <Annotation src={list?.imagePath} className="zoomImage" alt="Two pebbles anthropomorphized holding hands" annotations={[]} type="" value="" onChange={this.onChange} onSubmit="" allowTouch={true} renderEditor={false} renderContent={false} disableZoom={false} />
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </Dialog>
        );
    };

    handleNewWork = async () => {
        if (window.confirm("Are you sure you want new work?")) {
            const payload = {
                name: `Batch_${Date.now()}`,
                description: `Batch_Description_${Date.now()}`,
                assigned_to: this.sessionInformation.id,
                status: "Acceptance Pending",
                accepted_flag: false,
            };
            const addedRecord = await AddBatch(payload);
            if (addedRecord.status === false) {
                alert("No New Batch detected. Please contact admin.")
            } else {
                window.location = "/";
            }
            // if (addedRecord) {
                
            // } else {
            //     alert("Something went wrong. Please try again later.");
            // }
        }
    };

    render() {
        const { currentRecord, currentIndex, reportedList } = this.state;
        return (
            <div className="grid" align="center">
                <div className="col-12">
                    {reportedList && reportedList.length > 0 ? (
                        <Fragment>
                            <div className="flex justify-content-center align-items-center" style={{ position: "relative" }}>
                                <Button label="Prev" disabled={currentIndex === 0} className="mr-4" onClick={this.handlePrevious} />
                                <div id="annotedDiv" className="card p-fluid fixedScroll">
                                    <Annotation
                                        src={currentRecord?.imagePath}
                                        alt="Two pebbles anthropomorphized holding hands"
                                        annotations={this.state.annotations}
                                        type={this.state.type}
                                        value={this.state.annotation}
                                        onChange={this.onChange}
                                        onSubmit={this.onOpenSupportedImages}
                                        allowTouch={true}
                                        renderEditor={renderEditor}
                                        renderContent={renderContent}
                                        disableZoom={false}
                                    />
                                </div>

                                <Button className="ml-4" label="Next" disabled={currentIndex === reportedList.length - 1} onClick={this.handleNext} />
                            </div>
                            {currentIndex === reportedList.length - 1 && (
                                <div>
                                    <Button className="ml-4" label="Review and Submit" onClick={() => this.handleQAReview(currentRecord?.batch_id)} />
                                </div>
                            )}
                        </Fragment>
                    ) : (
                        <div className="card p-fluid">
                            There is no batch assigned to you. Click&nbsp;
                            <a href="javascript:;" className="p-button-link" onClick={this.handleNewWork}>
                                here&nbsp;
                            </a>
                            to get a new batch.
                        </div>
                    )}
                </div>
                {this.renderSupportedImages()}
            </div>
        );
    }
}
