import RestClient from "RestClient";

const restClient = new RestClient();

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const getAllTrainings = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/trainingmaterial`, payload);
    return result?.data;
};

export const addTraining = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/trainingmaterial/create`, payload);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const updateTraining = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/trainingmaterial/${id}`, payload);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const deleteTraining = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/trainingmaterial/${id}`);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const getTraining = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/trainingmaterial/${payload}`);
    return result?.data;
};

export const getTrainingByName = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/trainingmaterial/`, payload);
    return result?.data;
};

export const completeTraining = async (payload) => {
    const result = await restClient.put(`${BASE_URL}/users/trainingCompleted`, payload);
    return result?.data;
};
