import moment from "moment";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { getUserDevice, updateDevice, validateDevice } from "../devices.service";

const ConnectDevice = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [deviceInfo, setDeviceInfo] = useState(null);
    const [serialNumber, setSerialNumber] = useState(null);
    const toast = useRef();
    const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));

    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    useEffect(() => {
        // Check if user has assigned device
        const fetchDeviceInfo = async () => {
            setIsLoading(true);
            const record = await getUserDevice(sessionInformation?.id);
            if (record) {
                setDeviceInfo(record);
            }
            setIsLoading(false);
        };

        fetchDeviceInfo();
    }, []);

    const isValidDevice = async () => {
        setIsLoading(true);
        const record = await validateDevice(serialNumber);
        if (record) {
            // Update userId to device
            if (record?.assigend_to && record?.assigend_to !== sessionInformation?.id) {
                showError("Invalid Serial Number");
            } else {
                const payload = { ...record };
                payload.assigned_to = sessionInformation?.id;
                payload.assigned_date = moment().format("YYYY-MM-DD HH:MM:SS");
                const updatedRecord = await updateDevice(payload.id, payload);
                if (updatedRecord.status === false) {
                    showError(updatedRecord?.message);
                } else {
                    setDeviceInfo(updatedRecord);
                    showSuccess("Device Assignment done succssfully");
                }
            }
        } else {
            showError("Invalid Serial Number");
        }
        setIsLoading(false);
    };

    return (
        <Fragment>
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}

            <Toast ref={toast} className="ToastMessage" />
            {!deviceInfo ? (
                <Fragment>
                    <div className="card">
                        <h2>{!deviceInfo ? "Connect Your Device" : "Your Device Information"}</h2>
                        <InputText placeholder="Enter device serial number" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} />
                        <Button className="ml-4" disabled={!serialNumber} label="Connect Device" onClick={isValidDevice} />
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <div className="card" style={{width:"50%"}}>
                        <h2>{!deviceInfo ? "Connect Your Device" : "Device Information"}</h2>
                        <div className="flex">
                            <label>Sr. No:</label>
                            <label className="ml-4">
                                <strong>{deviceInfo?.serial_number}</strong>
                            </label>
                        </div>
                        <div className="flex">
                            <label>MacId:</label>
                            <label className="ml-4">
                                <strong>{deviceInfo?.mac_id}</strong>
                            </label>
                        </div>
                        <div>
                            <label>Assigned:</label>
                            <label className="ml-2">
                                <strong>{deviceInfo?.assigned_date ? moment(deviceInfo?.assigned_date).format("DD-MM-YYYY") : "-"}</strong>
                            </label>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default ConnectDevice;
