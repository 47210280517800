export const MOCK_DEVICES = [
    { id: "1", mac_id: "222222", serial_number: "SR1111", description: "Description 1", device_code: "DC111", device_make: "MK1", assigned_to: "Bhushan", assigned_date: "30-11-2021", device_linked_date: "30-11-2021", createdAt: "28-11-2021 20:18:22", created_by: "Admin", status: "Active" },
    { id: "2", mac_id: "111111", serial_number: "SR1112", description: "Description 2", device_code: "DC112", device_make: "MK2", assigned_to: "John", assigned_date: "30-11-2021", device_linked_date: "30-11-2021", createdAt: "28-11-2021 20:18:22", created_by: "Admin", status: "Active" },
];

export const MOCK_ROLES = [
    { id: "1", name: "Admin", createdAt: "28-11-2021 20:18:22", created_by: "Admin", status: "Active" },
    { id: "2", name: "Processor", createdAt: "28-11-2021 20:18:22", created_by: "Admin", status: "Active" },
];

export const MOCK_USERS = [
    { id: "1", firstname: "Bhushan", lastname: "Lunkad", middlename: "Shankarlal", fullname: "Bhushan Lunkad", email: "bhushan@interosys.com", password: "123456", status: "Active", role_id: "1" },
    { id: "2", firstname: "John", lastname: "Doe", middlename: "Jane", fullname: "John Doe", email: "john@interosys.com", password: "123456", status: "Active", role_id: "1" },
];

export const MOCK_VIOLATIONS = [
    { id: "1", name: "Violation 1", description: "Description 1", penalty_charges: 200.0, createdAt: "28-11-2021 20:18:22", created_by: "Admin", status: "Active" },
    { id: "2", name: "Violation 2", description: "Description 2", penalty_charges: 300.0, createdAt: "28-11-2021 20:18:22", created_by: "Admin", status: "Active" },
];

export const MOCK_QUIZ_QUESTIONS = [
    {
        question: "What franchise would you rather play a game from?",
        answers: [
            {
                content: "Halo",
            },
            {
                content: "Pokemon",
            },
            {
                content: "Uncharted",
            },
        ],
        correctAnswer: "Pokemon",
    },
    {
        question: "Which console would you prefer to play with friends?",
        answers: [
            {
                content: "X-Box",
            },
            {
                content: "Nintendo 64",
            },
            {
                content: "Playstation 1",
            },
        ],
        correctAnswer: "Playstation 1",
    },
    {
        question: "Which of these racing franchises would you prefer to play a game from?",
        answers: [
            {
                content: "Forza",
            },
            {
                content: "Mario Kart",
            },
            {
                content: "Gran Turismo",
            },
        ],
        correctAnswer: "Mario Kart",
    },
    {
        question: "Which of these games do you think is best?",
        answers: [
            {
                content: "BioShock",
            },
            {
                content: "The Legend of Zelda: Ocarina of Time",
            },
            {
                content: "Final Fantasy VII",
            },
        ],
        correctAnswer: "BioShock",
    },
    {
        question: "What console would you prefer to own?",
        answers: [
            {
                content: "X-Box One",
            },
            {
                content: "Wii U",
            },
            {
                content: "Playstation 4",
            },
        ],
        correctAnswer: "Wii U",
    },
];

export const MOCK_TRAINING = [
    {
        id: "1000",
        code: "f230fh0g3",
        name: "Bamboo Watch",
        description: "Product Description",
        image: "bamboo-watch.jpg",
        price: 65,
        category: "Accessories",
        quantity: 24,
        inventoryStatus: "INSTOCK",
        rating: 5,
    },
    {
        id: "1001",
        code: "nvklal433",
        name: "Black Watch",
        description: "Product Description",
        image: "black-watch.jpg",
        price: 72,
        category: "Accessories",
        quantity: 61,
        inventoryStatus: "INSTOCK",
        rating: 4,
    },
    {
        id: "1002",
        code: "zz21cz3c1",
        name: "Blue Band",
        description: "Product Description",
        image: "blue-band.jpg",
        price: 79,
        category: "Fitness",
        quantity: 2,
        inventoryStatus: "LOWSTOCK",
        rating: 3,
    },
    {
        id: "1003",
        code: "244wgerg2",
        name: "Blue T-Shirt",
        description: "Product Description",
        image: "blue-t-shirt.jpg",
        price: 29,
        category: "Clothing",
        quantity: 25,
        inventoryStatus: "INSTOCK",
        rating: 5,
    },
    {
        id: "1004",
        code: "h456wer53",
        name: "Bracelet",
        description: "Product Description",
        image: "bracelet.jpg",
        price: 15,
        category: "Accessories",
        quantity: 73,
        inventoryStatus: "INSTOCK",
        rating: 4,
    },
    {
        id: "1005",
        code: "av2231fwg",
        name: "Brown Purse",
        description: "Product Description",
        image: "brown-purse.jpg",
        price: 120,
        category: "Accessories",
        quantity: 0,
        inventoryStatus: "OUTOFSTOCK",
        rating: 4,
    },
    {
        id: "1006",
        code: "bib36pfvm",
        name: "Chakra Bracelet",
        description: "Product Description",
        image: "chakra-bracelet.jpg",
        price: 32,
        category: "Accessories",
        quantity: 5,
        inventoryStatus: "LOWSTOCK",
        rating: 3,
    },
    {
        id: "1007",
        code: "mbvjkgip5",
        name: "Galaxy Earrings",
        description: "Product Description",
        image: "galaxy-earrings.jpg",
        price: 34,
        category: "Accessories",
        quantity: 23,
        inventoryStatus: "INSTOCK",
        rating: 5,
    },
    {
        id: "1008",
        code: "vbb124btr",
        name: "Game Controller",
        description: "Product Description",
        image: "game-controller.jpg",
        price: 99,
        category: "Electronics",
        quantity: 2,
        inventoryStatus: "LOWSTOCK",
        rating: 4,
    },
];
