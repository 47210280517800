import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import ConfirmationDialogBox from "utils/ConfirmationDialogBox";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { completeTraining, deleteTraining, getAllTrainings } from "./training.service";
import { Carousel } from "primereact/carousel";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { storeLoggedInformation } from "auth/authSlice";

const Trainings = () => {
    const [trainings, setTrainings] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [trainingId, setTrainingId] = useState("");
    const toast = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    useEffect(() => {
        setIsLoading(true);
        const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));
        const fetchTrainings = async () => {
            const payload = {};
            if (sessionInformation?.role_id === 4) {
                payload.role_type = "Processor";
            }
            if (sessionInformation?.role_id === 3) {
                payload.role_type = "Reporter";
            }
            const trainingList = await getAllTrainings(payload);
            setTrainings(trainingList);
            setIsLoading(false);
        };
        // return () => {
        //     cleanup
        // }
        fetchTrainings();
    }, []);

    const handleConfirmation = async () => {
        const isDeleted = await deleteTraining(trainingId);
        if (isDeleted) {
            const findDeletedIndex = trainings.findIndex((training) => training.id === trainingId);
            trainings.splice(findDeletedIndex, 1);
            setTrainingId("");
            showSuccess("Record deleted successfully");
        } else {
            showError("Something went wrong.");
        }
        setDisplayConfirmation(false);
    };

    const handleCancelConfirmation = () => {
        setDisplayConfirmation(false);
    };
    const [page, setPage] = useState();
    const [visited, setVisited] = useState([0]);

    const handleVisited = (page) => {
        let prevVisitedValues = [...visited];
        if (!prevVisitedValues.includes(page)) {
            prevVisitedValues.push(page);
            setVisited(prevVisitedValues);
        }
        return;
    };

    const handleComplete = async () => {
        // training update flag in users table
        const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));
        const payload = { id: sessionInformation?.id, training_completed: "yes" };
        const updatedRecord = await completeTraining(payload);

        if (updatedRecord.status === false) {
            showError(updatedRecord?.message);
        } else {
            sessionInformation.training_completed = updatedRecord.training_completed;
            sessionStorage.setItem("userInfo", JSON.stringify(sessionInformation));
            dispatch(storeLoggedInformation(sessionInformation));
            showSuccess("Training completed successfully");
            alert("Training completed successfully");
            history.push("/");
        }
    };

    const handleCancel = () => {
        if (window.confirm("Are you sure you want to leave the page, your training will be lost")) {
            history.push("/");
        }
        return;
    };

    const productTemplate = (training) => {
        const { optionsPath, type } = training;
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div>
                        <h4 className="mb-1">{training?.module?.name}</h4>
                        <h6 className="mt-0 mb-3">{training?.chapter?.name}</h6>
                        <hr />
                        {type === "Image" && optionsPath && optionsPath?.associated ? (
                            <Fragment>
                                <div className="flex justify-content-center">
                                    {optionsPath?.associated.map((element) => {
                                        return <img src={element} className="mr-2" style={{ width: "auto", height: "180px" }} />;
                                    })}
                                </div>

                                <hr />
                            </Fragment>
                        ) : null}
                        {type === "Video" && optionsPath && optionsPath?.associated_video ? (
                            <Fragment>
                                <div className="flex justify-content-center">
                                    <video className="mt-4" height="200" controls>
                                        <source src={optionsPath?.associated_video} type="video/mp4" />
                                    </video>
                                </div>

                                <hr />
                            </Fragment>
                        ) : null}
                        <p style={{ minHeight: "200px" }}>{training?.description}</p>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="grid table-demo">
            <Toast ref={toast} className="ToastMessage" />
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            <div className="col-12">
                <div className="carousel-demo">
                    <div className="card fixedScroll">
                        <Carousel
                            value={trainings}
                            numVisible={1}
                            numScroll={1}
                            // orientation="vertical"
                            // verticalViewPortHeight="352px"
                            page={page}
                            onPageChange={(e) => {
                                setPage(e.page);
                                handleVisited(e.page);
                            }}
                            itemTemplate={productTemplate}
                            header={
                                <div>
                                    <h5 className="mb-5">Training</h5>
                                </div>
                            }
                            style={{ maxWidth: "auto", marginTop: "2em" }}
                        />
                    </div>
                    <div className="car-buttons p-mt-5 flex">
                        <Button disabled={trainings && visited.length !== trainings.length ? false : true} label="Cancel" onClick={handleCancel} className="p-button-success p-button-rounded mr-2" />
                        {trainings && visited.length === trainings.length ? <Button label="Complete" onClick={handleComplete} className="p-button-help p-button-rounded" /> : ""}
                    </div>
                </div>
            </div>
            {displayConfirmation && <ConfirmationDialogBox handleCancelConfirmation={handleCancelConfirmation} handleConfirmation={handleConfirmation} displayConfirmation={displayConfirmation} />}
        </div>
    );
};

export default Trainings;
