import RestClient from "RestClient";

const restClient = new RestClient();

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getAll = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/payouts`, { ...payload });
    return result?.data;
};

export const add = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/payouts/create`, payload);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const update = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/payouts/${id}`, payload);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const _delete = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/payouts/${id}`);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const getSingle = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/payouts/${payload}`);
    return result?.data;
};

export const getImagesCount = async (user_id, role_id) => {
    if (role_id === 3) {
        const result = await restClient.get(`${BASE_URL}/processeddata/processedImages/reporterImagesCount/`, { user_id });
        return result?.data;
    }
    if (role_id === 4) {
        const result = await restClient.get(`${BASE_URL}/processeddata/processedImages/processorImagesCount/`, { user_id });
        return result?.data;
    }
};
