import moment from "moment";
import { update } from "pages/Batches/batches.service";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ConfirmationDialogBox from "utils/ConfirmationDialogBox";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { announcementsList, checkIfBatchAssigned } from "./processorDashboard.service";

const ProcessorDashboard = () => {
    // Fetch Assigned Batch
    const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));
    const [isLoading, setIsLoading] = useState(false);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [batch, setBatch] = useState({});
    const [announcements, setAnnouncements] = useState(null);
    const history = useHistory();
    useEffect(() => {
        if (sessionInformation?.id && sessionInformation?.training_completed === "yes" && sessionInformation?.assessment_cleared === "yes") {
            setIsLoading(true);
            // Check If any batch is assigned to user, if assigned open popup
            const fetchAssignedBatch = async () => {
                const isBatchAssigned = await checkIfBatchAssigned(sessionInformation?.id);
                console.log("isBatchAssigned", isBatchAssigned);
                setIsLoading(false);
                setBatch(isBatchAssigned);
                if (isBatchAssigned && !isBatchAssigned?.accepted_flag && isBatchAssigned?.status === "Acceptance Pending") {
                    setDisplayConfirmation(true);
                }
            };

            const fetchAnnouncements = async () => {
                setIsLoading(true);
                const payload = {};
                payload.userId = sessionInformation?.id;
                if (sessionInformation?.role_id === 4) {
                    payload.role_type = "Processor";
                    
                }
                if (sessionInformation?.role_id === 3) {
                    payload.role_type = "Reporter";
                }
                const announcementsObj = await announcementsList(payload);
                setAnnouncements(announcementsObj);
                setIsLoading(false);
            };
            fetchAssignedBatch();
            fetchAnnouncements();
        }
    }, []);

    const toast = useRef();
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    const handleConfirmation = async () => {
        setIsLoading(true);
        const batchInformation = { ...batch };
        if (batchInformation) {
            batchInformation.accepted_flag = true;
            batchInformation.status = "Accepted";
            const updatedRecord = await update(batchInformation.id, batchInformation);
            setIsLoading(false);
            if (updatedRecord.status === false) {
                showError(updatedRecord?.message);
            } else {
                showSuccess("Batch accepted successfully");
                history.push("/annotation");
            }
        } else {
            setIsLoading(false);
        }
        setDisplayConfirmation(false);
    };

    const handleCancelConfirmation = async () => {
        setIsLoading(true);
        const batchInformation = { ...batch };
        if (batchInformation) {
            batchInformation.accepted_flag = false;
            batchInformation.status = "Rejected";
            const updatedRecord = await update(batchInformation.id, batchInformation);
            setIsLoading(false);
            if (updatedRecord.status === false) {
                showError(updatedRecord?.message);
            } else {
                showError("You have rejected the batch.");
            }
        } else {
            setIsLoading(false);
        }
        setDisplayConfirmation(false);
    };
    return (
        <Fragment>
            <Toast ref={toast} className="ToastMessage" />
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            {displayConfirmation && (
                <ConfirmationDialogBox handleCancelConfirmation={handleCancelConfirmation} handleConfirmation={handleConfirmation} displayConfirmation={displayConfirmation} headerContent="Accept Batch" mainContent="Batch has been assigned to you. Please accept to proceed the Annotation." />
            )}
            <div className="grid">
                <div className="col-12 md:col-8">
                    <div className="card p-fluid">Dashboard</div>
                </div>
                <div className="col-12 md:col-4">
                    <div className="card p-fluid">
                        Announcements
                        <hr />
                        <div className="holder">
                            {announcements && (
                                <ul id="ticker01">
                                    {announcements.map((ann) => {
                                        return (
                                            <Fragment>
                                                <li className="post-text">
                                                    <a className="pp-title" href="#">
                                                        {ann?.description}
                                                    </a>
                                                    <p className="mt-2">
                                                        <i className="pi pi-clock mr-2"></i>
                                                        {moment(ann.createdAt).format("DD-MM-YYYY HH:MM A")}
                                                    </p>
                                                </li>
                                                <hr />
                                            </Fragment>
                                        );
                                    })}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ProcessorDashboard;
