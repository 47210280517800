import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { statusList } from "utilities/utility.service";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { add, getSingle, update } from "../questionbank.service";
import { FileUpload } from "primereact/fileupload";
import { getExtension } from "utilities/utility.service";

const initialVales = {
    id: "",
    role_type: "",
    question_type: "Image",
    question_text: "",
    option1: "",
    option1Name: "",
    option2: "",
    option2Name: "",
    option3: "",
    option3Name: "",
    option4: "",
    option4Name: "",
    answer_key: "",
    associated_image: "",
    status: "",
    uniqueNumber: "",
    optionsPath: null,
};

const AddQuestionBank = () => {
    const { id } = useParams();
    const [values, setValues] = useState(initialVales);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef();
    const history = useHistory();
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    useEffect(() => {
        const fetchCurrentRecord = async () => {
            setIsLoading(true);
            const record = await getSingle(id);
            setValues(record);
            setIsLoading(false);
        };
        if (id) {
            fetchCurrentRecord();
        } else {
            setValues({ ...values, uniqueNumber: Date.now() });
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if (values?.question_type === "Image") {
        setIsLoading(true);
        let formData = new FormData();
        const { uniqueNumber } = values;
        // console.log(Object.keys(values));
        // Object.keys((values)=>{
        // Object.keys(values).forEach((value) => {
        //     console.log(value);
        //     // if (values[value] !== "") {
        //         formData.append(value, values[value]);
        //     // }
        // });
        if (values?.question_type === "Image") {
            values?.option1 && formData.append("images", values?.option1, `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_1.${getExtension(values?.option1Name)}`);
            values?.option2 && formData.append("images", values?.option2, `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_2.${getExtension(values?.option2Name)}`);
            values?.option3 && formData.append("images", values?.option3, `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_3.${getExtension(values?.option3Name)}`);
            values?.option4 && formData.append("images", values?.option4, `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_4.${getExtension(values?.option4Name)}`);

            formData.append("option1", `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_1.${getExtension(values?.option1Name)}`);
            formData.append("option2", `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_2.${getExtension(values?.option2Name)}`);
            formData.append("option3", `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_3.${getExtension(values?.option3Name)}`);
            formData.append("option4", `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_4.${getExtension(values?.option4Name)}`);
        } else {
            formData.append("option1", values?.option1);
            formData.append("option2", values?.option2);
            formData.append("option3", values?.option3);
            formData.append("option4", values?.option4);
        }
        let associatedImagesArray = [];
        if (values?.associated_image !== "") {
            let count = 5;
            for (const key of Object.keys(values?.associated_image)) {
                formData.append("images", values?.associated_image[key], `IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_${count}.${getExtension(values?.associated_image[key].name)}`);
                associatedImagesArray.push(`IMG_TRAINING_QUESTION_${uniqueNumber}_OPTION_${count}.${getExtension(values?.associated_image[key].name)}`);
                ++count;
            }
        }

        formData.append("question_type", values?.question_type);
        formData.append("question_text", values?.question_text);
        formData.append("answer_key", values?.answer_key);
        formData.append("status", values?.status);
        formData.append("role_type", values?.role_type);
        if (associatedImagesArray.length > 0) {
            formData.append("associated_image", associatedImagesArray.toString());
        }

        formData.append("uniqueNumber", uniqueNumber);
        // })
        // formData.append( ...values );
        if (id) {
            const updatedRecord = await update(id, formData);
            setValues(updatedRecord);
            setIsLoading(false);
            if (updatedRecord.status === false) {
                showError(updatedRecord?.message);
            } else {
                showSuccess("Record updated succssfully");
                history.push("/questionbanks");
            }
        } else {
            const addedRecord = await add(formData);
            setIsLoading(false);
            if (addedRecord.status === false) {
                showError(addedRecord?.message);
            } else {
                showSuccess("Record added succssfully");
                history.push("/questionbanks");
            }
        }
        // const addRecord = await postImages(formData);
        // const config = {
        //     headers: { "content-type": "multipart/form-data" },
        // };
        // } else {
        //     setIsLoading(true);
        //     if (id) {
        //         if (values.associated_image === null ? (values.associated_image = "") : values.associated_image);
        //         const updatedRecord = await update(id, values);
        //         setIsLoading(false);
        //         setValues(updatedRecord);
        //         if (updatedRecord.status === false) {
        //             showError(updatedRecord?.message);
        //         } else {
        //             showSuccess("Record updated succssfully");
        //         }
        //     } else {
        //         const addedRecord = await add(values);
        //         setIsLoading(false);
        //         if (addedRecord.status === false) {
        //             showError(addedRecord?.message);
        //         } else {
        //             showSuccess("Record added succssfully");
        //         }
        //         history.push("/questionbanks");
        //     }
        // }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const statuses = statusList();
    const roleTypes = [{ name: "Processor" }, { name: "Reporter" }];
    const questionTypes = [{ name: "Regular" }, { name: "Image" }];

    const multipleUploader = (event) => {
        if (event.files.length > 4) {
            // event.files.preventDefault();
            showError("You can upload only 4 Files");
            alert("You can upload only 4 Files");
            event.options.clear();
        } else {
            setValues({
                ...values,
                associated_image: event.files,
            });
        }
    };

    const myUploader = (event, name) => {
        setValues({
            ...values,
            [name]: event.files[0],
            [`${name}Name`]: event.files[0].name,
        });
    };

    const answerKeyList = [
        { name: "Option 1", value: "option1" },
        { name: "Option 2", value: "option2" },
        { name: "Option 3", value: "option3" },
        { name: "Option 4", value: "option4" },
    ];

    const RegularQuestionType = () => {
        return (
            <Fragment>
                <div className="field">
                    <label htmlFor="option1">Option 1*</label>
                    <InputText id="option1" name="option1" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="option1" value={values?.option1} />
                </div>
                <div className="field">
                    <label htmlFor="option2">Option 2*</label>
                    <InputText id="option2" name="option2" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="option2" value={values?.option2} />
                </div>
                <div className="field">
                    <label htmlFor="option3">Option 3</label>
                    <InputText id="option3" name="option3" type="text" onChange={(e) => handleInputChange(e)} placeholder="option3" value={values?.option3} />
                </div>
                <div className="field">
                    <label htmlFor="option4">Option 4</label>
                    <InputText id="option4" name="option4" type="text" onChange={(e) => handleInputChange(e)} placeholder="option4" value={values?.option4} />
                </div>
            </Fragment>
        );
    };

    const ImageQuestionTye = () => {
        return (
            <Fragment>
                <div className="field">
                    <label htmlFor="option1">Option 1*</label>
                    <div className="flex">
                        <FileUpload name="option1" mode="basic" customUpload uploadHandler={(event) => myUploader(event, "option1")} auto accept="image/*" />
                        {/* <input type="file" name="option1" onChange={(event) => myUploader(event, "option1")} /> */}
                        {!values?.optionsPath && !values?.optionsPath?.option1 && (
                            <label className="ml-4 mt-2">
                                <strong>{values?.option1Name}</strong>
                            </label>
                        )}
                        {values?.optionsPath && values?.optionsPath?.option1 && <img className="ml-4" src={values?.optionsPath?.option1} alt="Question 1" style={{ width: "auto", height: "100px" }} />}
                    </div>
                    {/* <InputText id="option1" name="option1" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="option1" value={values?.option1} /> */}
                </div>
                <div className="field">
                    <label htmlFor="option2">Option 2*</label>
                    <div className="flex">
                        {/* <input type="file" name="option2" onChange={(event) => myUploader(event, "option2")} /> */}
                        <FileUpload name="option2" mode="basic" customUpload uploadHandler={(event) => myUploader(event, "option2")} auto accept="image/*" />
                        {!values?.optionsPath && !values?.optionsPath?.option2 && (
                            <label className="ml-4 mt-2">
                                <strong>{values?.option2Name}</strong>
                            </label>
                        )}
                        {values?.optionsPath && values?.optionsPath?.option2 && <img className="ml-4" src={values?.optionsPath?.option2} alt="Question 2" style={{ width: "auto", height: "100px" }} />}
                    </div>
                </div>
                <div className="field">
                    <label htmlFor="option3">Option 3</label>
                    <div className="flex">
                        {/* <input type="file" name="option3" onChange={(event) => myUploader(event, "option3")} /> */}
                        <FileUpload name="option3" mode="basic" customUpload uploadHandler={(event) => myUploader(event, "option3")} auto accept="image/*" />
                        {!values?.optionsPath && !values?.optionsPath?.option3 && (
                            <label className="ml-4 mt-2">
                                <strong>{values?.option3Name}</strong>
                            </label>
                        )}
                        {values?.optionsPath && values?.optionsPath?.option3 && <img className="ml-4" src={values?.optionsPath?.option3} alt="Question 3" style={{ width: "auto", height: "100px" }} />}
                    </div>
                </div>
                <div className="field">
                    <label htmlFor="option4">Option 4</label>
                    <div className="flex">
                        {/* <input type="file" name="option4" onChange={(event) => myUploader(event, "option4")} /> */}
                        <FileUpload name="option4" mode="basic" customUpload uploadHandler={(event) => myUploader(event, "option4")} auto accept="image/*" />
                        {!values?.optionsPath && !values?.optionsPath?.option4 && (
                            <label className="ml-4 mt-2">
                                <strong>{values?.option4Name}</strong>
                            </label>
                        )}
                        {values?.optionsPath && values?.optionsPath?.option4 && <img className="ml-4" src={values?.optionsPath?.option4} alt="Question 4" style={{ width: "auto", height: "100px" }} />}
                    </div>
                </div>
            </Fragment>
        );
    };

    return (
        <Fragment>
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            <Toast ref={toast} className="ToastMessage" />
            <div className="mb-4">
                <Link to="/questionbanks">
                    <Button label="Back" className="p-button-sm p-button-success" />
                </Link>
            </div>
            <form method="POST" onSubmit={handleSubmit} autoComplete="Off">
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <div className="card p-fluid">
                            <h5>{id ? "Edit Question" : "Add Question"}</h5>
                            <div className="field">
                                <label htmlFor="role_type">Role Type*</label>
                                <Dropdown id="role_type" name="role_type" value={values?.role_type} options={roleTypes} optionLabel="name" optionValue="name" placeholder="Select Role" required={true} onChange={(e) => handleInputChange(e)}></Dropdown>
                            </div>
                            <div className="field">
                                <label htmlFor="question_type">Question Type*</label>
                                <Dropdown id="question_type" name="question_type" value={values?.question_type} options={questionTypes} optionLabel="name" optionValue="name" placeholder="Select Question Type" required={true} onChange={(e) => handleInputChange(e)}></Dropdown>
                            </div>
                            <div className="field">
                                <label htmlFor="question_text">Question Text*</label>
                                <InputText id="question_text" name="question_text" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Question Text" value={values?.question_text} />
                            </div>

                            {values?.question_type === "Image" && ImageQuestionTye()}

                            {values?.question_type === "Regular" && RegularQuestionType()}
                            <div className="field">
                                <label htmlFor="associated_image">Associated Image</label>

                                {values?.optionsPath && values?.optionsPath?.associated && (
                                    <div className="flex space-between">
                                        {values?.optionsPath?.associated.map((assoc) => {
                                            return <img className="mr-2 mb-4" alt="Associative" src={assoc} style={{ width: "auto", height: "100px" }} />;
                                        })}
                                    </div>
                                )}
                                {/* <InputText id="associated_image" name="associated_image" type="text" onChange={(e) => handleInputChange(e)} placeholder="Associated Image" value={values?.associated_image} /> */}
                                <FileUpload name="assoicated_image" multiple customUpload uploadHandler={(event) => multipleUploader(event)} auto accept="image/*" />
                            </div>
                            <div className="field">
                                <label htmlFor="answer_key">Answer Key*</label>
                                <Dropdown name="answer_key" options={answerKeyList} optionLabel="name" optionValue="value" onChange={(e) => handleInputChange(e)} required={true} value={values?.answer_key} placeholder="Select Answer Key From Drodown" />
                                {/* <InputTextarea id="answer_key" required={true} name="answer_key" onChange={(e) => handleInputChange(e)} placeholder="Option1, Option2" value={values?.answer_key} /> */}
                            </div>

                            <div className="field">
                                <label htmlFor="status">Status*</label>
                                <Dropdown id="status" name="status" value={values?.status} options={statuses} optionLabel="status" optionValue="status" placeholder="Select Status" required={true} onChange={(e) => handleInputChange(e)}></Dropdown>
                            </div>
                            <div>
                                <Button label="Save" type="submit" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default AddQuestionBank;
