import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ConfirmationDialogBox from "utils/ConfirmationDialogBox";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { deleteRecord, getAll } from "./announcements.service";

const Announcements = () => {
    const [list, setList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState("");
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [currentId, setCurrentId] = useState("");
    const toast = useRef();
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };
    const tableHeader = (
        <div className="table-header">
            List of Announcements
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const dateTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {moment(data[props.field]).format("DD-MM-YYYY HH:MM A")}
            </>
        );
    };

    const statusBodyTemplate = (data) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`customer-badge status-${data.status}`}>{data.status}</span>
            </>
        );
    };

    const descTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <div className="ellipsis" style={{ width: "200px" }} title={data.description}>
                    {data.description}
                </div>
            </>
        );
    };

    const actionBodyTemplate = (data) => {
        return (
            <>
                {/* <span className={`customer-badge edit`}>
                    <Link to={`/announcements/edit/${data.id}`}>Edit</Link>
                </span> */}
                <span className={`customer-badge edit`}>
                    <Button
                        label="Delete"
                        onClick={() => {
                            setDisplayConfirmation(true);
                            setCurrentId(data.id);
                        }}
                        className="p-button-link"
                    />
                </span>
            </>
        );
    };

    useEffect(() => {
        setIsLoading(true);
        const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));
        const payload = {};
        if (sessionInformation?.role_id === 4) {
            payload.role_type = "Processor";
        }
        if (sessionInformation?.role_id === 3) {
            payload.role_type = "Reporter";
        }
        const fetchAllRecords = async () => {
            const allRecords = await getAll(payload);
            setList(allRecords);
            setIsLoading(false);
        };
        // return () => {
        //     cleanup
        // }
        fetchAllRecords();
    }, []);

    const handleConfirmation = async () => {
        const isDeleted = await deleteRecord(currentId);
        if (isDeleted) {
            const findDeletedIndex = list.findIndex((record) => record.id === currentId);
            list.splice(findDeletedIndex, 1);
            setCurrentId("");
            showSuccess("Record deleted successfully");
        } else {
            showError("Something went wrong.");
        }
        setDisplayConfirmation(false);
    };

    const handleCancelConfirmation = () => {
        setDisplayConfirmation(false);
    };
    return (
        <div className="grid table-demo">
            <Toast ref={toast} className="ToastMessage" />
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            <div className="col-12">
                <div className="card">
                    <Link to="/announcements/add-new">
                        <Button label="Add New" className="mb-3" />
                    </Link>
                    {/* <h5>Customized</h5>
                    <p>
                        Scrollable table with gridlines (<mark>.p-datatable-gridlines</mark>), striped rows (<mark>.p-datatable-striped</mark>) and smaller paddings (<mark>p-datatable-sm</mark>).
                    </p> */}
                    <DataTable  className="p-datatable-gridlines p-datatable-striped p-datatable-sm" dataKey="id" paginator rowHover value={list} scrollable scrollHeight="400px" loading={isLoading} scrollDirection="both" globalFilter={globalFilter} emptyMessage="No records found." rows={10} header={tableHeader}>
                        <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="role_type" header="Role Type" sortable body={bodyTemplate}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: "216px" }} field="description" header="Description" sortable body={descTemplate}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: "150px" }} field="createdAt" header="Created At" sortable body={dateTemplate}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="status" header="Status" sortable body={statusBodyTemplate}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="status" header="Actions" sortable body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
            {displayConfirmation && <ConfirmationDialogBox handleCancelConfirmation={handleCancelConfirmation} handleConfirmation={handleConfirmation} displayConfirmation={displayConfirmation} />}
        </div>
    );
};

export default Announcements;
