import React, { useState, useEffect, Fragment } from "react";
import classNames from "classnames";
import { Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";

import { Dashboard } from "./components/Dashboard";
import { ButtonDemo } from "./components/ButtonDemo";
import { ChartDemo } from "./components/ChartDemo";
import { Documentation } from "./components/Documentation";
import { FileDemo } from "./components/FileDemo";
import { FloatLabelDemo } from "./components/FloatLabelDemo";
import { FormLayoutDemo } from "./components/FormLayoutDemo";
import { InputDemo } from "./components/InputDemo";
import { ListDemo } from "./components/ListDemo";
import { MenuDemo } from "./components/MenuDemo";
import { MessagesDemo } from "./components/MessagesDemo";
import { MiscDemo } from "./components/MiscDemo";
import { OverlayDemo } from "./components/OverlayDemo";
import { PanelDemo } from "./components/PanelDemo";
import { TableDemo } from "./components/TableDemo";
import { TreeDemo } from "./components/TreeDemo";
import { InvalidStateDemo } from "./components/InvalidStateDemo";

import { Crud } from "./pages/Crud";
import { EmptyPage } from "./pages/EmptyPage";
import { TimelineDemo } from "./pages/TimelineDemo";
import Login from "./auth/Login";

import PrimeReact from "primereact/api";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUserInformation, storeLoggedInformation } from "./auth/authSlice";
import Users from "pages/Users/Users";
import AddUser from "pages/Users/AddUser/AddUser";
import AddRole from "pages/Roles/AddRole/AddRole";
import Roles from "pages/Roles/Roles";
import Devices from "pages/Devices/Devices";
import AddDevice from "pages/Devices/AddDevice/AddDevice";
import Violations from "pages/Violations/Violations";
import AddViolation from "pages/Violations/AddViolation/AddViolation";
// import Annotation from "pages/Annotation/Annotation";

import Signup from "auth/Signup";
import Trainings from "pages/Trainings/Trainings";
import AddTraining from "pages/Trainings/AddTraining/AddTraining";
import Batches from "pages/Batches/Batches";
import AddBatch from "pages/Batches/AddBatch/AddBatch";
import Assessment from "pages/Assessment/Assessment";
import Modules from "pages/Modules/Modules";
import AddModule from "pages/Modules/AddModule/AddModule";
import Chapters from "pages/Chapters/Chapters";
import AddChapter from "pages/Chapters/AddChapter/AddChapter";
import Topics from "pages/Topics/Topics";
import AddTopic from "pages/Topics/AddTopic/AddTopic";
import QuestionBanks from "pages/QuestionBanks/QuestionBanks";
import AddQuestionBank from "pages/QuestionBanks/AddQuestionBank/AddQuestionBank";
import ProcessorDashboard from "pages/ProcessorRole/ProcessorDashboard";
import ProcessedBatch from "pages/Batches/ProcessedBatch/ProcessedBatch";
import Annotated from "pages/Annotation/Annotation";
import SearchViolation from "pages/Violations/SearchViolation/SearchViolation";
import Payouts from "pages/Payouts/Payouts";
import AddPayouts from "pages/Payouts/AddPayout/AddPayouts";
import ConnectDevice from "pages/Devices/ConnectDevice/ConnectDevice";
import CalibrateDevice from "pages/Devices/CalibrateDevice/CalibrateDevice";
import Announcements from "pages/Announcements/Announcements";
import AddAnnouncement from "pages/Announcements/AddAnnouncement/AddAnnouncement";
import Profile from "pages/Users/AddUser/Profile";

const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [processorMenu, setProcessorMenu] = useState([
        {
            label: "Home",
            items: [{ label: "Trainings", icon: "pi pi-fw pi-list", to: "/trainings" }],
        },
        {
            label: "Help",
            items: [
                { label: "FAQs", icon: "pi pi-fw pi-list", to: "/faqs" },
                { label: "Contact Details", icon: "pi pi-fw pi-bookmark", to: "/contact-details" },
            ],
        },
    ]);
    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;
    const dispatch = useDispatch();
    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    const loggedInUserInformation = useSelector(getLoggedInUserInformation);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const menu = [
        {
            label: "Home",
            items: [
                {
                    label: "Dashboard",
                    icon: "pi pi-fw pi-home",
                    to: "/",
                },
                {
                    label: "Review Violations",
                    icon: "pi pi-fw pi-bookmark",
                    to: "/search-violation",
                },
                {
                    label: "Payouts",
                    icon: "pi pi-fw pi-file",
                    to: "/payouts",
                },
                {
                    label: "Announcements",
                    icon: "pi pi-fw pi-bookmark",
                    to: "/announcements",
                },
            ],
        },
        {
            label: "Masters",
            icon: "pi pi-fw pi-sitemap",
            items: [
                { label: "Batches", icon: "pi pi-fw pi-circle-off", to: "/batches" },
                { label: "Devices", icon: "pi pi-fw pi-bookmark", to: "/devices" },
                { label: "Violations", icon: "pi pi-fw pi-exclamation-circle", to: "/violations" },
                {
                    label: "Training Material",
                    icon: "pi pi-fw pi-bookmark",
                    items: [
                        {
                            label: "Question Banks",
                            icon: "pi pi-fw pi-bookmark",
                            to: "/questionbanks",
                        },
                        {
                            label: "Topics",
                            icon: "pi pi-fw pi-bookmark",
                            to: "/topics",
                        },
                        {
                            label: "Chapters",
                            icon: "pi pi-fw pi-bookmark",
                            to: "/chapters",
                        },
                        {
                            label: "Modules",
                            icon: "pi pi-fw pi-bookmark",
                            to: "/modules",
                        },
                    ],
                },
                // { label: "Roles", icon: "pi pi-fw pi-check-square", to: "/roles" },
                { label: "Users", icon: "pi pi-fw pi-id-card", to: "/users" },
            ],
        },
        {
            label: "Help",
            items: [
                { label: "FAQs", icon: "pi pi-fw pi-list", to: "/faqs" },
                { label: "Contact Details", icon: "pi pi-fw pi-bookmark", to: "/contact-details" },
            ],
        },
        // {
        //     label: "UI Kit",
        //     icon: "pi pi-fw pi-sitemap",
        //     items: [
        //         { label: "Form Layout", icon: "pi pi-fw pi-id-card", to: "/formlayout" },
        //         { label: "Input", icon: "pi pi-fw pi-check-square", to: "/input" },
        //         { label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel" },
        //         { label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "invalidstate" },
        //         { label: "Button", icon: "pi pi-fw pi-mobile", to: "/button" },
        //         { label: "Table", icon: "pi pi-fw pi-table", to: "/table" },
        //         { label: "List", icon: "pi pi-fw pi-list", to: "/list" },
        //         { label: "Tree", icon: "pi pi-fw pi-share-alt", to: "/tree" },
        //         { label: "Panel", icon: "pi pi-fw pi-tablet", to: "/panel" },
        //         { label: "Overlay", icon: "pi pi-fw pi-clone", to: "/overlay" },
        //         { label: "Menu", icon: "pi pi-fw pi-bars", to: "/menu" },
        //         { label: "Message", icon: "pi pi-fw pi-comment", to: "/messages" },
        //         { label: "File", icon: "pi pi-fw pi-file", to: "/file" },
        //         { label: "Chart", icon: "pi pi-fw pi-chart-bar", to: "/chart" },
        //         { label: "Misc", icon: "pi pi-fw pi-circle-off", to: "/misc" },
        //     ],
        // },
        // {
        //     label: "Pages",
        //     icon: "pi pi-fw pi-clone",
        //     items: [
        //         { label: "Crud", icon: "pi pi-fw pi-user-edit", to: "/crud" },
        //         { label: "Timeline", icon: "pi pi-fw pi-calendar", to: "/timeline" },
        //         { label: "Empty", icon: "pi pi-fw pi-circle-off", to: "/empty" },
        //     ],
        // },
        // {
        //     label: "Menu Hierarchy",
        //     icon: "pi pi-fw pi-search",
        //     items: [
        //         {
        //             label: "Submenu 1",
        //             icon: "pi pi-fw pi-bookmark",
        //             items: [
        //                 {
        //                     label: "Submenu 1.1",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 1.1.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 1.1.2", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 1.1.3", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //                 {
        //                     label: "Submenu 1.2",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 1.2.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 1.2.2", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //             ],
        //         },
        //         {
        //             label: "Submenu 2",
        //             icon: "pi pi-fw pi-bookmark",
        //             items: [
        //                 {
        //                     label: "Submenu 2.1",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 2.1.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 2.1.2", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 2.1.3", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //                 {
        //                     label: "Submenu 2.2",
        //                     icon: "pi pi-fw pi-bookmark",
        //                     items: [
        //                         { label: "Submenu 2.2.1", icon: "pi pi-fw pi-bookmark" },
        //                         { label: "Submenu 2.2.2", icon: "pi pi-fw pi-bookmark" },
        //                     ],
        //                 },
        //             ],
        //         },
        //     ],
        // },
        // {
        //     label: "Get Started",
        //     items: [
        //         {
        //             label: "Documentation",
        //             icon: "pi pi-fw pi-question",
        //             command: () => {
        //                 window.location = "#/documentation";
        //             },
        //         },
        //         {
        //             label: "View Source",
        //             icon: "pi pi-fw pi-search",
        //             command: () => {
        //                 window.location = "https://github.com/primefaces/sakai-react";
        //             },
        //         },
        //     ],
        // },
    ];

    useEffect(() => {
        if (!loggedInUserInformation?.id) {
            const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));
            dispatch(storeLoggedInformation(sessionInformation));
        }
        if (loggedInUserInformation && loggedInUserInformation?.training_completed === "yes" && !loggedInUserInformation?.assessment_cleared) {
            const newItems = [...processorMenu];

            newItems[0].items.push({ label: "Assessments", icon: "pi pi-fw pi-bookmark", to: "/assessments" });
            setProcessorMenu(newItems);
        }
        if (loggedInUserInformation && loggedInUserInformation?.training_completed === "yes" && loggedInUserInformation?.assessment_cleared === "yes" && loggedInUserInformation?.role_id === 4) {
            const newItems = [
                {
                    label: "Home",
                    items: [
                        {
                            label: "Dashboard",
                            icon: "pi pi-fw pi-home",
                            to: "/",
                        },
                        {
                            label: "My Work",
                            icon: "pi pi-fw pi-mobile",
                            to: "/annotation",
                        },
                        {
                            label: "My Payouts",
                            icon: "pi pi-fw pi-file",
                            to: "/payouts",
                        },
                        // { label: "My Batches", icon: "pi pi-fw pi-circle-off", to: "/batches" },
                        { label: "Assessments", icon: "pi pi-fw pi-list", to: "/assessments" },
                        { label: "Trainings", icon: "pi pi-fw pi-bookmark", to: "/trainings" },
                    ],
                },
                {
                    label: "Help",
                    items: [
                        { label: "FAQs", icon: "pi pi-fw pi-list", to: "/faqs" },
                        { label: "Contact Details", icon: "pi pi-fw pi-bookmark", to: "/contact-details" },
                    ],
                },
            ];
            setProcessorMenu(newItems);
        }
        if (loggedInUserInformation && loggedInUserInformation?.training_completed === "yes" && loggedInUserInformation?.assessment_cleared === "yes" && loggedInUserInformation?.role_id === 3) {
            const newItems = [
                {
                    label: "Home",
                    items: [
                        {
                            label: "Dashboard",
                            icon: "pi pi-fw pi-home",
                            to: "/",
                        },
                        {
                            label: "My Payouts",
                            icon: "pi pi-fw pi-file",
                            to: "/payouts",
                        },
                        { label: "Calibrate Device", icon: "pi pi-fw pi-bookmark", to: "/devices/calibrate-device" },
                        { label: "Connect Device", icon: "pi pi-fw pi-bookmark", to: "/devices/connect-device" },
                        { label: "Assessments", icon: "pi pi-fw pi-list", to: "/assessments" },
                        { label: "Trainings", icon: "pi pi-fw pi-bookmark", to: "/trainings" },
                    ],
                },
                {
                    label: "Help",
                    items: [
                        { label: "FAQs", icon: "pi pi-fw pi-list", to: "/faqs" },
                        { label: "Contact Details", icon: "pi pi-fw pi-bookmark", to: "/contact-details" },
                    ],
                },
            ];
            setProcessorMenu(newItems);
        }
    }, [loggedInUserInformation?.training_completed, loggedInUserInformation?.assessment_cleared]);

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));
    useEffect(() => {
        if (loggedInUserInformation && loggedInUserInformation?.id) {
            setIsLoggedIn(true);
        }
        if (sessionInformation && sessionInformation.id) {
            setIsLoggedIn(true);
        }
    }, [loggedInUserInformation]);
    return (
        <Fragment>
            <div className={wrapperClass} onClick={onWrapperClick}>
                <AppTopbar
                    onToggleMenuClick={onToggleMenuClick}
                    layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive}
                    onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                    onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
                    isLoggedIn={isLoggedIn}
                    loggedInUserInformation={loggedInUserInformation}
                />
                <div className="wrapperContainer">
                    {isLoggedIn && sessionInformation.role_id === 1 && (
                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} isLoggedIn={isLoggedIn} />
                        </div>
                    )}

                    {isLoggedIn && loggedInUserInformation.role_id !== 1 && (
                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            <AppMenu model={processorMenu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} isLoggedIn={isLoggedIn} />
                        </div>
                    )}

                    {!isLoggedIn && (
                        <div className="">
                            <div className="container-fluid flex justify-content-center align-items-center">
                                <div className="layout-logIn">
                                    <Route path="/" exact component={Login} />
                                    <Route path="/login" exact component={Login} />
                                    <Route path="/signup/:id" exact component={Signup} />
                                    <AppFooter layoutColorMode={layoutColorMode} />
                                </div>
                            </div>
                        </div>
                    )}

                    {isLoggedIn && loggedInUserInformation.role_id === 4 && (
                        <div className="layout-main-container">
                            <div className="layout-main">
                                <Route path="/" exact component={ProcessorDashboard} />
                                <Route path="/annotation" exact component={Annotated} />
                                <Route path="/trainings" exact component={Trainings} />
                                <Route path="/assessments" exact component={Assessment} />
                                <Route path="/batches" exact component={Batches} />
                                <Route path="/batches/processedImages/:id" exact component={ProcessedBatch} />
                                <Route path="/payouts" exact component={Payouts} />
                                <Route path="/payouts/add-new" exact component={AddPayouts} />
                                <Route path="/payouts/edit/:id" exact component={AddPayouts} />
                                <Route path="/profile/:id" exact component={Profile} />
                                <AppFooter layoutColorMode={layoutColorMode} />
                            </div>
                        </div>
                    )}

                    {isLoggedIn && loggedInUserInformation.role_id === 3 && (
                        <div className="layout-main-container">
                            <div className="layout-main">
                                <Route path="/" exact component={ProcessorDashboard} />
                                <Route path="/annotation" exact component={Annotated} />
                                <Route path="/trainings" exact component={Trainings} />
                                <Route path="/assessments" exact component={Assessment} />
                                <Route path="/devices/calibrate-device" exact component={CalibrateDevice} />
                                <Route path="/devices/connect-device" exact component={ConnectDevice} />
                                <Route path="/payouts" exact component={Payouts} />
                                <Route path="/payouts/add-new" exact component={AddPayouts} />
                                <Route path="/payouts/edit/:id" exact component={AddPayouts} />
                                <Route path="/profile/:id" exact component={Profile} />
                                <AppFooter layoutColorMode={layoutColorMode} />
                            </div>
                        </div>
                    )}
                    {isLoggedIn && sessionInformation.role_id === 1 && (
                        <div className="layout-main-container">
                            <div className="layout-main">
                                <Route path="/" exact component={Dashboard} />
                                <Route path="/dashboard" exact component={Dashboard} />
                                <Route path="/processor-dashboard" exact component={ProcessorDashboard} />
                                <Route path="/formlayout" exact component={FormLayoutDemo} />
                                <Route path="/input" exact component={InputDemo} />
                                <Route path="/floatlabel" exact component={FloatLabelDemo} />
                                <Route path="/invalidstate" exact component={InvalidStateDemo} />
                                <Route path="/button" exact component={ButtonDemo} />
                                <Route path="/table" exact component={TableDemo} />
                                <Route path="/list" exact component={ListDemo} />
                                <Route path="/tree" exact component={TreeDemo} />
                                <Route path="/panel" exact component={PanelDemo} />
                                <Route path="/overlay" exact component={OverlayDemo} />
                                <Route path="/menu" exact component={MenuDemo} />
                                <Route path="/messages" exact component={MessagesDemo} />
                                <Route path="/file" exact component={FileDemo} />
                                <Route path="/chart" exact component={ChartDemo} />
                                <Route path="/misc" exact component={MiscDemo} />
                                <Route path="/timeline" exact component={TimelineDemo} />
                                <Route path="/crud" exact component={Crud} />
                                <Route path="/empty" exact component={EmptyPage} />
                                <Route path="/documentation" exact component={Documentation} />
                                <Route path="/users" exact component={Users} />
                                <Route path="/users/add-new" exact component={AddUser} />
                                <Route path="/users/edit/:id" exact component={AddUser} />
                                <Route path="/roles" exact component={Roles} />
                                <Route path="/roles/add-new" exact component={AddRole} />
                                <Route path="/roles/edit/:id" exact component={AddRole} />
                                <Route path="/devices" exact component={Devices} />
                                <Route path="/devices/add-new" exact component={AddDevice} />
                                <Route path="/devices/edit/:id" exact component={AddDevice} />
                                <Route path="/violations" exact component={Violations} />
                                <Route path="/violations/add-new" exact component={AddViolation} />
                                <Route path="/violations/edit/:id" exact component={AddViolation} />
                                {/* <Route path="/annotation" exact component={Annotated} /> */}
                                {/* <Route path="/assessments" exact component={Assessment} /> */}
                                {/* <Route path="/trainings" exact component={Trainings} /> */}
                                <Route path="/trainings/add-new" exact component={AddTraining} />
                                <Route path="/trainings/edit/:id" exact component={AddTraining} />
                                <Route path="/batches" exact component={Batches} />
                                <Route path="/batches/add-new" exact component={AddBatch} />
                                <Route path="/batches/edit/:id" exact component={AddBatch} />
                                <Route path="/batches/processedImages/:id" exact component={ProcessedBatch} />
                                <Route path="/modules" exact component={Modules} />
                                <Route path="/modules/add-new" exact component={AddModule} />
                                <Route path="/modules/edit/:id" exact component={AddModule} />
                                <Route path="/chapters" exact component={Chapters} />
                                <Route path="/chapters/add-new" exact component={AddChapter} />
                                <Route path="/chapters/edit/:id" exact component={AddChapter} />
                                <Route path="/topics" exact component={Topics} />
                                <Route path="/topics/add-new" exact component={AddTopic} />
                                <Route path="/topics/edit/:id" exact component={AddTopic} />
                                <Route path="/questionbanks" exact component={QuestionBanks} />
                                <Route path="/questionbanks/add-new" exact component={AddQuestionBank} />
                                <Route path="/questionbanks/edit/:id" exact component={AddQuestionBank} />
                                <Route path="/search-violation" exact component={SearchViolation} />
                                <Route path="/payouts" exact component={Payouts} />
                                <Route path="/payouts/add-new" exact component={AddPayouts} />
                                <Route path="/payouts/edit/:id" exact component={AddPayouts} />
                                <Route path="/announcements" exact component={Announcements} />
                                <Route path="/announcements/add-new" exact component={AddAnnouncement} />
                                <Route path="/announcements/edit/:id" exact component={AddAnnouncement} />
                            </div>

                            <AppFooter layoutColorMode={layoutColorMode} />
                        </div>
                    )}
                </div>
                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>
            </div>
        </Fragment>
    );
};

export default App;
