import { fetchReportedData } from "pages/ProcessorRole/processorDashboard.service";
import { postAnnotation } from "pages/ProcessorRole/processorDashboard.service";
import { checkIfBatchAssigned } from "pages/ProcessorRole/processorDashboard.service";
import { getAllViolations } from "pages/Violations/violations.service";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { Component, Fragment } from "react";
import Annotation from "react-image-annotation-with-zoom";
import { Link, useParams } from "react-router-dom";
import { fetchProcessedDataByBatchID, getSingle, update as updateBatch } from "../batches.service";
import { update } from "./processedbatch.service";

function renderContent({ annotation }) {
    const { geometry } = annotation;
    return (
        <div
            key={annotation.data.id}
            style={{
                background: "black",
                color: "white",
                padding: 10,
                position: "absolute",
                fontSize: 12,
                left: `${geometry.x}%`,
                top: `${geometry.y + geometry.height}%`,
            }}
        >
            <div> {annotation.data && annotation.data.text}</div>
            {annotation.data && annotation.data.vehicleNumber}
        </div>
    );
}

function renderEditor(props) {
    return null;
    const { geometry, violationList } = props.annotation;
    if (!geometry) return null;

    return (
        <div
            style={{
                background: "white",
                borderRadius: 3,
                position: "absolute",
                left: `${geometry.x}%`,
                top: `${geometry.y + geometry.height}%`,
                padding: "1rem",
            }}
        >
            <div>Annotate </div>
            <div className="flex mb-2 mt-2">
                <Dropdown
                    placeholder="Select Violation"
                    onChange={(e) =>
                        props.onChange({
                            ...props.annotation,
                            data: {
                                ...props.annotation.data,
                                text: e.target.value,
                            },
                        })
                    }
                    optionLabel="name"
                    optionValue="name"
                    options={violationList}
                    className="mr-2"
                    value={props.annotation && props.annotation.data && props.annotation.data.text}
                />
                <InputText
                    placeholder="Vehicle Number"
                    onChange={(e) =>
                        props.onChange({
                            ...props.annotation,
                            data: {
                                ...props.annotation.data,
                                vehicleNumber: e.target.value,
                            },
                        })
                    }
                />
            </div>
            <Button disabled={!props?.annotation?.data} onClick={props.onSubmit} label="Submit" />
        </div>
    );
}

const convertToAnnotedObject = (record) => {
    return {
        id: record?.id,
        mark: record?.mark
            ? { ...record.mark }
            : {
                  x: 84.572359208148,
                  y: 107.81434414346614,
                  width: 211.56069364161849,
                  height: 83.2369942196532,
                  type: "RECT",
              },
        comment: record?.comment ? record?.comment : "",
        image: record?.image,
    };
};

export default class Annotated extends Component {
    state = {
        annotations: [],
        annotation: {
            violationList: [],
        },
        reportedList: [],
        currentIndex: 0,
        currentRecord: {},
    };

    constructor(props) {
        super(props);
    }

    // const { id: batchId } = useParams();

    sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));

    componentDidMount = () => {
        const fetchViolations = async () => {
            const violationList = await getAllViolations();
            if (violationList) {
                if (violationList) {
                    const annotationCopy = { ...this.state.annotation };
                    annotationCopy.violationList = violationList;
                    this.setState({ annotation: annotationCopy });
                }
            }
        };

        const fetchProcessedBatch = async () => {
            const payload = { batch_id: this.props.match.params.id };
            const fetchProcessed = await fetchProcessedDataByBatchID(payload);
            this.setState({ reportedList: fetchProcessed });
            if (this.state.reportedList) {
                this.setState({ annotations: [JSON.parse(fetchProcessed[0]?.annoted_data)], currentRecord: this.state.reportedList[0] });
                // setIData([convertToAnnotedObject(JSON.parse(fetchProcessed[0]?.annoted_data))]);
            }
        };

        fetchProcessedBatch();
        fetchViolations();
    };

    onChange = (annotation) => {
        this.setState({ annotation });
    };

    getViolationId = (violationRecord) => {
        const record = this.state.annotation.violationList.find((violation) => violation.name === violationRecord);
        if (record) {
            return record.id;
        }
        return null;
    };

    getViolationRecord = (violationId) => {
        const record = this.state.annotation.violationList.find((violation) => violation.id === violationId);
        if (record) {
            return record;
        }
        return null;
    };

    onSubmit = async (annotation) => {
        const { geometry, data } = annotation;

        const currentRecord = this.state.reportedList[this.state.currentIndex];
        let annotationData = [
            {
                geometry,
                data: {
                    ...data,
                    id: Math.random(),
                    image: currentRecord.image,
                },
            },
        ];
        this.setState({
            annotation: {},
            // annotations: this.state.annotations.concat({
            //     geometry,
            //     data: {
            //         ...data,
            //         id: Math.random(),
            //     },
            // }),
            annotations: annotationData,
        });

        let payload = {
            batch_id: currentRecord?.batch_id,
            event_id: currentRecord?.event_id,
            reported_data_id: currentRecord?.id,
            violation_id: this.getViolationId(annotationData[0]?.data?.text),
            vehicle_number: annotationData[0]?.data?.vehicleNumber,
            annoted_data: annotationData[0],
            line_number: 1,
            processor_id: this.sessionInformation.id,
        };
        // console.log("payload",payload);
        const addedRecord = await postAnnotation(payload);
        if (addedRecord.status === false) {
            alert(addedRecord?.message);
        } else {
            alert("Annoted Image Saved Successfully");

            window.location.reload();
        }
    };

    handleNext = () => {
        const { annotations, currentIndex, reportedList } = this.state;
        let index = currentIndex;
        this.setState({ currentIndex: index + 1, currentRecord: reportedList[index + 1], annotations: [JSON.parse(reportedList[index + 1]?.annoted_data)] });
    };

    handlePrevious = () => {
        const { annotations, currentIndex, reportedList } = this.state;
        let index = currentIndex;
        this.setState({ currentIndex: index - 1, currentRecord: reportedList[index - 1], annotations: [JSON.parse(reportedList[index - 1]?.annoted_data)] });
    };

    isValid = async () => {
        if (window.confirm("Are you sure you want to mark it as Valid Violation")) {
            const { currentRecord, currentIndex, reportedList } = this.state;
            let payload = { ...currentRecord };
            payload.isValid = "yes";
            payload.annoted_data = JSON.parse(payload?.annoted_data);

            //Violation
            const violationRecord = this.getViolationRecord(currentRecord?.violation_id);
            if (violationRecord) {
                payload.calculated_amount = parseFloat(violationRecord.penalty_charges);
            }
            const updateRecord = await update(payload.id, payload);
            if (updateRecord.status === false) {
                alert(updateRecord?.message);
            } else {
                alert("Record Updated Successfully");
                let index = currentIndex;
                // const newCurrentRecord = {...currentRecord};
                // newCurrentRecord.isValid = "yes";
                const newReportedList = [...reportedList];
                newReportedList[index].isValid = "yes";
                this.setState({ currentIndex: index, currentRecord: newReportedList[index], annotations: [JSON.parse(newReportedList[index]?.annoted_data)] });
                // this.setState({ currentIndex: index + 1, currentRecord: reportedList[index + 1], annotations: [JSON.parse(reportedList[index + 1]?.annoted_data)] });
            }
        }
    };

    processImageAgain = () => {
        if (window.confirm("Are you sure you want to go back for processing the images?")) {
            this.props.history.push("/annotation");
        }
    };

    isInValid = async () => {
        if (window.confirm("Are you sure you want to mark it as In Valid Violation?")) {
            const { currentRecord, currentIndex, reportedList } = this.state;
            let payload = { ...currentRecord };
            payload.isValid = "no";
            payload.annoted_data = JSON.parse(payload?.annoted_data);
            payload.calculated_amount = 0;
            const updateRecord = await update(payload.id, payload);
            if (updateRecord.status === false) {
                alert(updateRecord?.message);
            } else {
                alert("Record Updated Successfully");
                let index = currentIndex;
                // const newCurrentRecord = {...currentRecord};
                // newCurrentRecord.isValid = "yes";
                const newReportedList = [...reportedList];
                newReportedList[index].isValid = "no";
                this.setState({ currentIndex: index, currentRecord: newReportedList[index], annotations: [JSON.parse(newReportedList[index]?.annoted_data)] });
                // this.setState({ currentIndex: index + 1, currentRecord: reportedList[index + 1], annotations: [JSON.parse(reportedList[index + 1]?.annoted_data)] });
            }
        }
    };

    doSubmit = async () => {
        if (window.confirm("Are you sure you want to submit the work")) {
            const batchRecord = await getSingle(this.props.match.params.id);
            if (batchRecord) {
                let paylod = {
                    name: batchRecord.name,
                    assigned_to: batchRecord.assigned_to,
                    description: batchRecord.description,
                    status: "Processed",
                    accepted_flag: true,
                };

                const updatedRecord = await updateBatch(batchRecord?.id, paylod);
                if (updatedRecord.status === false) {
                    alert("Unable to save the data. Please try again.");
                } else {
                    alert("Your work has been saved successfully");
                    window.location = "/";
                }
            }
        }
    };

    render() {
        const { currentRecord, reportedList, annotations, currentIndex } = this.state;
        return (
            <Fragment>
                <div className="mb-4">
                    <Link to="/batches">
                        <Button label="Back" className="p-button-sm p-button-success" />
                    </Link>
                </div>
                {reportedList && reportedList.length > 0 ? (
                    <div className="grid">
                        <div className="col-12">
                            <div className="flex justify-content-center align-items-center">
                                <Button label="Prev" disabled={currentIndex === 0} className="mr-4" onClick={this.handlePrevious} />
                                <div className="card p-fluid fixedScroll">
                                    <Annotation
                                        src={currentRecord?.imagePath}
                                        alt="Two pebbles anthropomorphized holding hands"
                                        annotations={this.state.annotations}
                                        type={this.state.type}
                                        value={this.state.annotation}
                                        onChange={this.onChange}
                                        // onSubmit={this.onOpenSupportedImages}
                                        allowTouch={true}
                                        renderEditor={renderEditor}
                                        renderContent={renderContent}
                                    />
                                </div>

                                <Button className="ml-4" label="Next" disabled={currentIndex === reportedList.length - 1} onClick={this.handleNext} />
                            </div>
                            {!currentRecord?.isValid && (
                                <Fragment>
                                    {/* {this.sessionInformation && this.sessionInformation?.role_id === 1 && <Button label="Valid" className="mr-2 p-button-success" onClick={this.isValid} />}
                                    <Button label="Invalid" className="p-button-warning mr-2" onClick={this.isInValid} /> */}
                                    {this.sessionInformation && this.sessionInformation.role_id !== 1 && (
                                        <Fragment>
                                            <Button label="Want to process image again?" className="p-button mr-2" onClick={this.processImageAgain} />
                                            <Button label="Submit" className="p-button-success mr-2" onClick={this.doSubmit} />
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                            {/* {currentRecord?.isValid && currentRecord?.isValid !== "yes" && <Button label="Valid" className="mr-2 p-button-success" onClick={this.isValid} />}
                            {currentRecord?.isValid && currentRecord?.isValid === "yes" && <Button label="Invalid" className="p-button-warning mr-2" onClick={this.isInValid} />} */}
                            {/* {currentIndex === reportedList.length - 1 && <Button label="Complete" className=" p-button-success" onClick={this.handleComplete} />} */}
                        </div>
                    </div>
                ) : (
                    <Fragment>
                        <div className="card p-fluid">No Processed Images found.</div>
                        <Button label="Want to process image again?" className="p-button mr-2" onClick={this.processImageAgain} />
                        <Button label="Submit" className="p-button-success mr-2" onClick={this.doSubmit} />
                    </Fragment>
                )}
            </Fragment>
        );
    }
}
