import RestClient from "RestClient";

const restClient = new RestClient();

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const update = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/processeddata/${id}`, payload);
    return result?.data;
};
