import RestClient from "RestClient";

const restClient = new RestClient();

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const getAll = async () => {
    const result = await restClient.get(`${BASE_URL}/modules`, []);
    return result?.data;
};

export const add = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/modules/create`, payload);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const update = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/modules/${id}`, payload);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const deleteRecord = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/modules/${id}`);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        // return { status: false, message: data?.message };
        return result;
    }
};

export const getSingle = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/modules/${payload}`);
    return result?.data;
};

export const getByName = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/modules/`, payload);
    return result?.data;
};
