import React from "react";
import PropTypes from "prop-types";

function AnswerOption(props) {
    return (
        <li className="answerOption">
            <input type="radio" className="radioCustomButton" name="radioGroup" checked={props.answerType === props.answer} id={props.randomId} value={props.answerType} disabled={props.answer} onChange={props.onAnswerSelected} />
            {props.questionType && props.questionType === "Regular" ? (
                <label className="radioCustomLabel ml-4" htmlFor={props.randomId}>
                    {props.answerContent}
                </label>
            ) : (
                <label className="radioCustomLabel ml-4" htmlFor={props.randomId}>
                    <img src={props.answerContent} style={{ height: "100px" }} />
                </label>
            )}
        </li>
    );
}

AnswerOption.propTypes = {
    answerType: PropTypes.string.isRequired,
    answerContent: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
    onAnswerSelected: PropTypes.func.isRequired,
};

export default AnswerOption;
