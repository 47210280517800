import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React, { Component, Fragment } from "react";
import { getAllViolations, search, updateReviewedData } from "../violations.service";
import { ProgressSpinner } from "primereact/progressspinner";
import Annotation from "react-image-annotation-with-zoom";
import { getAllUsers } from "pages/Users/users.service";
import { RadioButton } from "primereact/radiobutton";

function renderContent({ annotation }) {
    const { geometry } = annotation;
    return (
        <div
            key={annotation.data.id}
            style={{
                background: "black",
                color: "white",
                padding: 10,
                position: "absolute",
                fontSize: 12,
                left: `${geometry.x}%`,
                top: `${geometry.y + geometry.height}%`,
            }}
        >
            <div> {annotation.data && annotation.data.text}</div>
            {annotation.data && annotation.data.vehicleNumber}
        </div>
    );
}

function renderEditor(props) {
    return null;
}

class SearchViolation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            violationId: null,
            status: null,
            dateRange: null,
            violationList: [],
            searchList: [],
            statusList: [{ name: "Reviewed" }, { name: "Not Reviewed" }],
            isLoading: false,
            annotations: [],
            annotation: {
                violationList: [],
            },
            inValidIDS: [],
            message: "Please select review criteria to proceed...",
            userList: [],
            selectedUser: null,
            roleType: null,
            flag: null,
        };

        this.fetchViolations();
        this.fetchUserList();
    }

    fetchViolations = async () => {
        const violationList = await getAllViolations();
        if (violationList) {
            this.setState({ violationList: violationList });
        }
    };

    fetchUserList = async () => {
        const userList = await getAllUsers();
        if (userList) {
            this.setState({ userList: userList });
        }
    };

    handleSearch = async () => {
        const { dateRange, status, violationId, selectedUser, flag, userList } = this.state;
        this.setState({ isLoading: true });
        const payload = {
            status,
            violationId,
            startDate: dateRange ? moment(dateRange[0]).format("YYYY-MM-DD") : null,
            endDate: dateRange ? moment(dateRange[1]).format("YYYY-MM-DD") : null,
            selectedUser,
            flag,
        };
        if (selectedUser) {
            const find = userList.find((user) => user.id === selectedUser);
            if (find) {
                if (find?.role_id === 3) {
                    console.log("inside");
                    payload.roleType = "Reporter";
                    this.setState({ roleType: "Reporter" });
                }
                if (find?.role_id === 4) {
                    console.log("inside2");
                    payload.roleType = "Processor";
                    this.setState({ roleType: "Processor" });
                }
            }
        }

        const records = await search(payload);
        this.setState({ isLoading: false });
        if (records && records.length > 0) {
            this.setState({ searchList: records, message: "Please select review criteria to proceed review..." });
        } else {
            alert("Please enter valid review criteria");
            this.setState({ searchList: [], message: "No Records found. Please use different review criteria to proceed..." });
        }
    };

    onChangeCheckBox = (id, status) => {
        let inValidIDSCopy = [...this.state.inValidIDS];
        // check if already exists
        // const findIndex = inValidIDSCopy.findIndex((si) => si === value);
        // if (findIndex === -1) {
        //     inValidIDSCopy.push(value);
        //     this.setState((prevState) => ({
        //         ...prevState,
        //         inValidIDS: inValidIDSCopy,
        //     }));
        // } else {
        //     inValidIDSCopy.splice(findIndex, 1);
        //     this.setState((prevState) => ({
        //         ...prevState,
        //         inValidIDS: inValidIDSCopy,
        //     }));
        // }

        const checkIfIDExists = inValidIDSCopy.findIndex((ids) => ids.id === id);
        if (checkIfIDExists !== -1) {
            inValidIDSCopy[checkIfIDExists].isValid = status === "valid" ? true : false;
            this.setState((prevState) => ({
                ...prevState,
                inValidIDS: inValidIDSCopy,
            }));
        } else {
            inValidIDSCopy.push({
                id,
                isValid: status === "valid" ? true : false,
            });
            this.setState((prevState) => ({
                ...prevState,
                inValidIDS: inValidIDSCopy,
            }));
        }
    };

    handleSubmit = async () => {
        const { inValidIDS, searchList } = this.state;

        this.setState({ isLoading: true });
        // const filteredList = searchList.filter(function (objFromA) {
        //     return !inValidIDS.find(function (objFromB) {
        //         return objFromA.id === objFromB;
        //     });
        // });
        const newList = searchList.map(function (fl) {
            return fl.id;
        });

        if (newList.length !== inValidIDS.length) {
            this.setState({ isLoading: false });
            alert("Please validate all the records to proceed further.");
            return;
        }

        //Flag IDS
        let inValidFilterIds = null;
        const filterInvalidRecords = inValidIDS.filter((invalid) => invalid.isValid === false);
        if (filterInvalidRecords.length > 0) {
            inValidFilterIds = filterInvalidRecords.map(function (fir) {
                return fir.id;
            });
        }
        const payload = {
            inValidIds: inValidFilterIds,
            reviewedIds: newList,
        };

        const record = await updateReviewedData(payload);

        this.setState({ isLoading: false });
        if (record) {
            alert("Record updated successfully");
            window.location.reload();
        } else {
            alert("Something went wrong. Please try again.");
        }
    };

    // dataviewHeader = (
    //     <div className="grid grid-nogutter">
    //         <div className="col-6" style={{ textAlign: 'left' }}>
    //             <Dropdown value={sortKey} options={sortOptions} optionLabel="label" placeholder="Sort By Price" onChange={onSortChange} />
    //         </div>
    //         <div className="col-6" style={{ textAlign: 'right' }}>
    //             <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
    //         </div>
    //     </div>
    // );

    render() {
        const { violationList, statusList, violationId, status, isLoading, searchList, inValidIDS, message, userList, flag } = this.state;
        return (
            <Fragment>
                <div className="card">
                    <h2>Review Violation Records</h2>
                    <div className="grid">
                        <div className="col-12 md:col-3">
                            <Dropdown
                                className="w-v-100 mr-2"
                                value={this.state.selectedUser}
                                options={userList}
                                onChange={(e) => this.setState({ selectedUser: e.value })}
                                optionLabel="firstName"
                                optionValue="id"
                                filter
                                showClear
                                filterBy="firstName"
                                placeholder="Select a User"
                                // valueTemplate={this.selectedCountryTemplate}
                                // itemTemplate={this.countryOptionTemplate}
                            />
                        </div>
                        <div className="col-12 md:col-3">
                            <Dropdown placeholder="Select Violation" onChange={(e) => this.setState({ violationId: e.value })} className="w-v-100 mr-2" options={violationList} optionLabel="name" optionValue="id" value={violationId} />
                        </div>
                        <div className="col-12 md:col-3">
                            <Dropdown placeholder="Select Status" className="mr-2" onChange={(e) => this.setState({ status: e.value })} className="w-v-100 mr-2" options={statusList} optionLabel="name" optionValue="name" value={status} />
                        </div>
                        <div className="col-12 md:col-3">
                            <Dropdown placeholder="Select Flag" className="w-v-100 mr-2" onChange={(e) => this.setState({ flag: e.value })} className="mr-2" options={["yes", "no"]} value={flag} />
                        </div>
                        <div className="col-12 md:col-3">
                            <Calendar placeholder="Select Date Range" className="w-v-100 mr-2" onChange={(e) => this.setState({ dateRange: e.value })} value={this.state.dateRange} selectionMode="range" readOnlyInput />
                        </div>
                        <div className="col-12 md:col-3">
                            <Button icon="pi pi-search" onClick={this.handleSearch} className="p-button" />
                        </div>
                    </div>
                    {isLoading && <ProgressSpinner />}
                </div>
                {searchList && searchList.length > 0 ? (
                    <Fragment>
                        <div className="card fixedScroll">
                            <div className="grid">
                                {searchList.map((sl) => {
                                    let annotedData = [];
                                    annotedData = JSON.parse(sl.annoted_data);
                                    const checkedRecord = inValidIDS && inValidIDS.find((si) => si.id === sl.id);
                                    return (
                                        <div className="col-12 md:col-4">
                                            <div className="flex justify-content-center align-items-center">
                                                <div>
                                                    <RadioButton className="validRadio" onChange={(e) => this.onChangeCheckBox(sl.id, "valid")} value="valid" checked={checkedRecord && checkedRecord?.isValid === true} />
                                                    <RadioButton className="inValidRadio" onChange={(e) => this.onChangeCheckBox(sl.id, "invalid")} value={sl.id} checked={checkedRecord && checkedRecord?.isValid === false} />
                                                </div>
                                                <Annotation
                                                    src={sl?.imagePath}
                                                    alt="Two pebbles anthropomorphized holding hands"
                                                    annotations={[annotedData]}
                                                    type="RECT"
                                                    // value={this.state.annotation}
                                                    // onChange={this.onChange}
                                                    // onSubmit={this.onOpenSupportedImages}
                                                    allowTouch={true}
                                                    renderEditor={renderEditor}
                                                    renderContent={renderContent}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <Button label="Submit" onClick={this.handleSubmit} className="p-button p-button-success" />
                    </Fragment>
                ) : (
                    <div className="card fixedScroll">{message}</div>
                )}

                {/* <DataView value={dataviewValue} layout={layout} paginator rows={9} sortOrder={sortOrder} sortField={sortField} itemTemplate={itemTemplate} header={dataviewHeader}></DataView> */}
            </Fragment>
        );
    }
}

export default SearchViolation;
