import React from "react";
import PropTypes from "prop-types";
// import { CSSTransition } from "react-transition-group";

function Result(props) {
    console.log(props);
    return (
        // <CSSTransition className="container result" component="div" transitionName="fade" transitionEnterTimeout={800} transitionLeaveTimeout={500} transitionAppear transitionAppearTimeout={500}>
        <div>
            <strong>{props.quizResult === "Pass" ? "Congratulations!!!You have completed the Assessment" : "Sorry, You are failed. Please come back later"}</strong>
        </div>
        // </CSSTransition>
    );
}

Result.propTypes = {
    quizResult: PropTypes.string.isRequired,
};

export default Result;
