import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import ConfirmationDialogBox from "utils/ConfirmationDialogBox";
import { deleteDevice, getAllDevices } from "./devices.service";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { Toast } from "primereact/toast";

const Devices = () => {
    const [devices, setDevices] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState("");
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [deviceId, setDeviceId] = useState("");
    const toast = useRef();
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };
    const tableHeader = (
        <div className="table-header">
            List of Devices
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const statusBodyTemplate = (data) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`customer-badge status-${data.status}`}>{data.status}</span>
            </>
        );
    };

    const actionBodyTemplate = (data) => {
        return (
            <>
                <span className={`customer-badge edit`}>
                    <Link to={`/devices/edit/${data.id}`}>Edit</Link>
                </span>
                <span className={`customer-badge edit`}>
                    <Button
                        label="Delete"
                        onClick={() => {
                            setDisplayConfirmation(true);
                            setDeviceId(data.id);
                        }}
                        className="p-button-link"
                    />
                </span>
            </>
        );
    };

    useEffect(() => {
        setIsLoading(true);
        const fetchDevices = async () => {
            const deviceList = await getAllDevices();
            setDevices(deviceList);
            setIsLoading(false);
        };
        // return () => {
        //     cleanup
        // }
        fetchDevices();
    }, []);

    const handleConfirmation = async () => {
        const isDeleted = await deleteDevice(deviceId);
        if (isDeleted) {
            const findDeletedIndex = devices.findIndex((device) => device.id === deviceId);
            devices.splice(findDeletedIndex, 1);
            setDeviceId("");
            showSuccess("Record deleted successfully");
        } else {
            showError("Something went wrong.");
        }
        setDisplayConfirmation(false);
    };

    const handleCancelConfirmation = () => {
        setDisplayConfirmation(false);
    };

    return (
        <div className="grid table-demo">
            <Toast ref={toast} className="ToastMessage" />
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            <div className="col-12">
                <div className="card">
                    <Link to="/devices/add-new">
                        <Button label="Add New" className="mb-3" />
                    </Link>
                    {/* <h5>Customized</h5>
                    <p>
                        Scrollable table with gridlines (<mark>.p-datatable-gridlines</mark>), striped rows (<mark>.p-datatable-striped</mark>) and smaller paddings (<mark>p-datatable-sm</mark>).
                    </p> */}
                    <DataTable
                        value={devices}
                        scrollable
                        scrollHeight="600px"
                        scrollDirection="both"
                        className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                        rows={10}
                        dataKey="id"
                        paginator
                        rowHover
                        globalFilter={globalFilter}
                        emptyMessage="No devices found."
                        loading={isLoading}
                        header={tableHeader}
                    >
                        <Column style={{ flexGrow: 1, flexBasis: "150px" }} field="id" header="Id" sortable body={bodyTemplate}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="mac_id" header="Mac Id" sortable body={bodyTemplate}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="serial_number" header="Serial Number" sortable body={bodyTemplate}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="assigned_to" header="Assigned To" sortable body={bodyTemplate}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="device_linked_date" header="Device Linked" sortable body={bodyTemplate}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: "200px" }} field="status" header="Status" sortable body={statusBodyTemplate}></Column>
                        <Column style={{ flexGrow: 1, flexBasis: "250px" }} field="" header="Actions" sortable body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
            {displayConfirmation && <ConfirmationDialogBox handleCancelConfirmation={handleCancelConfirmation} handleConfirmation={handleConfirmation} displayConfirmation={displayConfirmation} />}
        </div>
    );
};

export default Devices;
