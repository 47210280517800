import React from "react";

const CalibrateDevice = () => {
    return (
        <div className="card" style={{ width: "50%" }}>
            <h2>Calibrate Device</h2>
            <div className="flex justify-content-center">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/waUahh8DG4M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    );
};

export default CalibrateDevice;
