import { getAllRoles } from "pages/Roles/roles.service";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { statusList } from "utilities/utility.service";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { getUser, updateUser } from "../users.service";

const initialVales = {
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    password: "",
    email: "",
    dob: "",
    role_id: "",
    pancard: "",
    aadhar: "",
    status: "",
    bankName: "",
    ifsc: "",
    bankAccountNumber: "",
    micr: "",
};

const Profile = () => {
    const { id } = useParams();
    const [values, setValues] = useState(initialVales);
    const [roles, setRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef();

    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    useEffect(() => {
        const fetchUser = async () => {
            setIsLoading(true);
            const record = await getUser(id);
            setValues(record);
            setIsLoading(false);
        };
        if (id) {
            fetchUser();
        }

        const fetchRoles = async () => {
            const roles = await getAllRoles();
            setRoles(roles);
        };
        fetchRoles();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (id) {
            setIsLoading(true);
            const updatedRecord = await updateUser(id, values);
            setValues(updatedRecord);
            if (updatedRecord.status === false) {
                setIsLoading(false)
                showError(updatedRecord?.message);
            } else {
                setIsLoading(false)
                showSuccess("Profile updated succssfully");
            }
        } else {
            showError("Please logout and login again");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const statuses = statusList();
    const { firstName, middleName, lastName, role_id, status, email, mobileNumber, bankAccountNumber, bankName, ifsc, micr } = values;
    console.log("values",values);
    return (
        <Fragment>
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            <Toast ref={toast} className="ToastMessage" />
            {/* <div className="mb-4">
                <Link to="/users">
                    <Button label="Back" className="p-button-sm p-button-success" />
                </Link>
            </div> */}
            <form method="POST" onSubmit={handleSubmit} autoComplete="Off">
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card p-fluid">
                            <h5>{id ? "Profile" : "Add User"}</h5>
                            <div className="grid">
                                <div className="col-12 md:col-4">
                                    <div className="field">
                                        <label htmlFor="firstName">First Name*</label>
                                        <InputText id="firstName" name="firstName" type="text" required={true} onChange={(e) => handleInputChange(e)} value={firstName} placeholder="Firstname" />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="lastName">Last Name*</label>
                                        <InputText id="lastName" name="lastName" type="text" required={true} onChange={(e) => handleInputChange(e)} value={lastName} placeholder="Last Name" />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="email">Email*</label>
                                        <InputText id="email" disabled={true} name="email" type="email" required={true} onChange={(e) => handleInputChange(e)} value={email} placeholder="Email" />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="role">Role*</label>
                                        <Dropdown id="state" disabled={true} name="role_id" options={roles} optionLabel="name" optionValue="id" placeholder="Select Role" required={true} onChange={(e) => handleInputChange(e)} value={role_id}></Dropdown>
                                    </div>
                                </div>
                                <div className="col-12 md:col-4">
                                    <div className="field">
                                        <label htmlFor="middleName">Middle Name</label>
                                        <InputText id="middleName" name="middleName" type="text" onChange={(e) => handleInputChange(e)} value={middleName} placeholder="Middlename" />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="mobileNumber">Mobile Number*</label>
                                        <InputText id="mobileNumber" disabled={mobileNumber && mobileNumber.length === 10} name="mobileNumber" type="text" required={true} maxLength={10} onChange={(e) => handleInputChange(e)} value={mobileNumber} placeholder="Mobile Number" />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="password">Password</label>
                                        <InputText type="password" name="password" id="password" required={id ? false : true} onChange={(e) => handleInputChange(e)} placeholder="Password" />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="role">Status*</label>
                                        <Dropdown id="status" disabled={true} name="status" options={statuses} optionLabel="status" optionValue="status" placeholder="Select Status" required={true} onChange={(e) => handleInputChange(e)} value={status}></Dropdown>
                                    </div>
                                </div>
                                <div className="col-12 md:col-4">
                                    <div className="field">
                                        <label htmlFor="bankName">Bank Name</label>
                                        <InputText id="bankName" name="bankName" type="text" onChange={(e) => handleInputChange(e)} value={bankName} placeholder="Bank Name" />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="bankAccountNumber">Account Number</label>
                                        <InputText id="bankAccountNumber" name="bankAccountNumber" type="text" required={false} maxLength={20} onChange={(e) => handleInputChange(e)} value={bankAccountNumber} placeholder="Account Number" />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="ifsc">IFSC</label>
                                        <InputText id="ifsc" name="ifsc" type="text" required={false} maxLength={20} onChange={(e) => handleInputChange(e)} value={ifsc} placeholder="IFSC" />
                                    </div>
                                    <div className="field">
                                        <label htmlFor="micr">MICR</label>
                                        <InputText id="micr" name="micr" type="text" required={false} maxLength={20} onChange={(e) => handleInputChange(e)} value={micr} placeholder="MICR" />
                                    </div>
                                    <div style={{ width: "50%", float: "right" }}>
                                        <Button label="Save" type="submit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default Profile;
