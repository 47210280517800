import React, { Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Menu } from "primereact/menu";

export const AppTopbar = (props) => {
    const { isLoggedIn, loggedInUserInformation } = props;

    const menu1 = useRef(null);

    const doLogout = () => {
        sessionStorage.clear();
        window.location = "/";
    };

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                {/* <img src={props.layoutColorMode === "light" ? "assets/layout/images/logo-dark.svg" : "assets/layout/images/logo-white.svg"} alt="logo" /> */}
                {/* <span>U4U</span> */}
                <img src="assets/layout/images/U4ULogo.png" alt="Logo" />
            </Link>

            {isLoggedIn && (
                <Fragment>
                    <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                        <i className="pi pi-bars" />
                    </button>

                    <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                        <i className="pi pi-ellipsis-v" />
                    </button>

                    <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                        {/* <li>
                            <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                                <i className="pi pi-calendar" />
                                <span>Events</span>
                            </button>
                        </li>
                        <li>
                            <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                                <i className="pi pi-cog" />
                                <span>Settings</span>
                            </button>
                        </li> */}
                        {loggedInUserInformation?.role_id !== 1 ? (
                            <li>
                                <button
                                    className="p-link layout-topbar-button"
                                    onClick={(event) => {
                                        props.onMobileSubTopbarMenuClick(event);
                                        menu1.current.toggle(event);
                                    }}
                                >
                                    <i className="pi pi-user" />
                                    <Menu
                                        ref={menu1}
                                        popup
                                        model={[
                                            {
                                                label: "Profile",
                                                icon: "pi pi-fw pi-user",
                                                command: () => {
                                                    window.location.hash = `/profile/${loggedInUserInformation?.id}`;
                                                },
                                            },
                                            {
                                                label: "Logout",
                                                icon: "pi pi-fw pi-minus",
                                                command: () => {
                                                    doLogout();
                                                },
                                            },
                                        ]}
                                    />

                                    <span>Profile</span>
                                </button>
                            </li>
                        ) : (
                            <button
                                className="p-link layout-topbar-button"
                                onClick={(event) => {
                                    props.onMobileSubTopbarMenuClick(event);
                                    menu1.current.toggle(event);
                                }}
                            >
                                <i className="pi pi-user" />
                                <Menu
                                    ref={menu1}
                                    popup
                                    model={[
                                        {
                                            label: "Logout",
                                            icon: "pi pi-fw pi-minus",
                                            command: () => {
                                                doLogout();
                                            },
                                        },
                                    ]}
                                />

                                <span>Profile</span>
                            </button>
                        )}
                    </ul>
                </Fragment>
            )}
        </div>
    );
};
