import RestClient from "RestClient";

const restClient = new RestClient();

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getAllViolations = async () => {
    const result = await restClient.get(`${BASE_URL}/violations`, []);
    return result?.data;
};

export const addViolation = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/violations/create`, payload);
    return result?.data;
};

export const updateViolation = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/violations/${id}`, payload);
    return result?.data;
};

export const deleteViolation = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/violations/${id}`);
    return result?.data;
};

export const getViolation = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/violations/${payload}`);
    return result?.data;
};

export const search = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/processeddata/search`, payload);
    return result?.data;
};

export const updateReviewedData = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/processeddata/updateReviewedData`, payload);
    return result?.data;
};
