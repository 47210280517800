import React, { Component } from "react";
import PowerbiEmbedded from "react-powerbi";
import PowerBiSettings from "utils/PowerBiSettings";

class PowerBi extends Component {
    onEmbedded = (_dataRecived) => {
        console.log("data", _dataRecived);
    };

    render() {
        // token type 1 is embedTokenURL
        // token type 0 is ADD token
        console.log("here");
        return (
            <div>
                <PowerbiEmbedded id={`${3}`} embedUrl={`${PowerBiSettings.embedUrl}`} accessToken={`04e79433daf348449118f24670f3f7e8`} filterPaneEnabled={false} navContentPaneEnabled={false} tokenType={1} width="1200px" height="350px" mobile={true}  />
            </div>
        );
    }
}

export default PowerBi;
