import { storeLoggedInformation } from "auth/authSlice";
import { MOCK_QUIZ_QUESTIONS } from "mocks/mock";
import { getRole } from "pages/Roles/roles.service";
import { completeTraining } from "pages/Trainings/training.service";
import React, { Component } from "react";
import { convertAssessmentToRequired } from "utilities/utility.service";
import { add, getAll } from "./assessment.service";
import { connect } from "react-redux";

import Quiz from "./components/Quiz";
import Result from "./components/Result";
class Assessment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            counter: 0,
            questionId: 1,
            question: "",
            questionType: "",
            answerOptions: [],
            answer: "",
            isAnswerCorrect: false,
            answersCount: {},
            correctAnswerCount: 0,
            result: "",
            questionsList: [],
            associatedImages: [],
        };
        this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
    }

    async componentDidMount() {
        const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));
        const roleRecord = await getRole(sessionInformation?.role_id);
        // console.log("roleName", roleName);
        // if (roleName) {
        const QUIZ_QUESTIONS = await getAll(roleRecord?.name);
        const newQuizQuestions = await convertAssessmentToRequired(QUIZ_QUESTIONS);
        if (newQuizQuestions) {
            this.setState({ questionsList: newQuizQuestions });
            if (newQuizQuestions && newQuizQuestions.length > 0) {
                const shuffledAnswerOptions = newQuizQuestions.map((question) => this.shuffleArray(question.answers));
                this.setState({
                    question: newQuizQuestions[0].question,
                    answerOptions: shuffledAnswerOptions[0],
                    questionType: newQuizQuestions[0].questionType,
                    associatedImages: newQuizQuestions[0].associatedImages,
                });
            }
        }
        // }
    }

    shuffleArray(array) {
        var currentIndex = array.length,
            temporaryValue,
            randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    handleAnswerSelected(event) {
        const { questionsList } = this.state;
        this.setUserAnswer(event.currentTarget.value);
        if (this.state.questionId < questionsList.length) {
            setTimeout(() => this.setNextQuestion(), 300);
        } else {
            setTimeout(() => this.getResults(), 300);
        }
    }

    setUserAnswer(answer) {
        const { questionsList } = this.state;
        const findQuestion = questionsList[this.state.counter];
        this.setState((state, props) => ({
            answersCount: {
                ...state.answersCount,
                [answer]: (state.answersCount[answer] || 0) + 1,
            },
            correctAnswerCount: findQuestion.correctAnswer === answer ? state.correctAnswerCount + 1 : state.correctAnswerCount,
            answer: answer,
            isAnswerCorrect: findQuestion.correctAnswer === answer ? true : false,
        }));
    }

    setNextQuestion() {
        const counter = this.state.counter + 1;
        const questionId = this.state.questionId + 1;

        const { questionsList } = this.state;
        this.setState({
            counter: counter,
            questionId: questionId,
            question: questionsList[counter].question,
            questionType: questionsList[counter].questionType,
            answerOptions: questionsList[counter].answers,
            answer: "",
            isAnswerCorrect: false,
            associatedImages: questionsList[counter].associatedImages,
        });
    }

    async getResults() {
        const { questionsList } = this.state;
        const totalQuestions = questionsList.length;
        const totalCorrectAnswers = this.state.correctAnswerCount;
        const calculatePercentage = (totalCorrectAnswers / totalQuestions) * 100;
        const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));
        const payload = { user_id: sessionInformation.id, result: calculatePercentage < 80 ? "fail" : "pass" };
        const addedRecord = add(payload).then((res) => {
            console.log("calculatePercentage", res);
            if (calculatePercentage < 80) {
                this.setState({ result: "Fail" });
                alert("Sorry, You are failed. Please come back later");
                return calculatePercentage;
            } else {
                // update and dispatch
                this.setState({ result: "Pass" });
                const payload = { id: sessionInformation?.id, training_completed: sessionInformation?.training_completed, assessment_cleared: "yes" };
                completeTraining(payload).then((ress) => {
                    if (ress.status === false) {
                        alert("Something went wrong");
                    } else {
                        sessionInformation.assessment_cleared = ress.assessment_cleared;
                        sessionStorage.setItem("userInfo", JSON.stringify(sessionInformation));
                        this.props.storeLoggedInformation(sessionInformation);

                        return calculatePercentage;
                    }
                });
            }
        });

        // const answersCount = this.state.answersCount;
        // const answersCountKeys = Object.keys(answersCount);
        // const answersCountValues = answersCountKeys.map((key) => answersCount[key]);
        // const maxAnswerCount = Math.max.apply(null, answersCountValues);

        // return answersCountKeys.filter((key) => answersCount[key] === maxAnswerCount);
    }

    setResults(result) {
        if (result >= 80) {
            this.setState({ result: "Pass" });
            alert("Congratulations!!!You have completed the Assessment");
            this.props.history.push("/");
        } else {
            this.setState({ result: "Fail" });
        }
        // if (result.length === 1) {
        //     this.setState({ result: result[0] });
        // } else {
        //     this.setState({ result: "Undetermined" });
        // }
    }

    renderQuiz() {
        const { questionsList } = this.state;
        if (questionsList && questionsList.length > 0) {
            return (
                <Quiz
                    associatedImages={this.state.associatedImages}
                    questionType={this.state.questionType}
                    answer={this.state.answer}
                    answerOptions={this.state.answerOptions}
                    questionId={this.state.questionId}
                    question={this.state.question}
                    questionTotal={questionsList.length}
                    onAnswerSelected={this.handleAnswerSelected}
                />
            );
        } else {
            return <label>You cannot take assessment as of now. Please contact Admin for more information. Thanks!</label>;
        }
    }

    renderResult() {
        console.log(this.state);
        return <Result quizResult={this.state.result} />;
    }

    render() {
        return (
            <div className="card p-fluid fixedScroll">
                <h5>Assessment</h5>
                <div>{this.state.result ? this.renderResult() : this.renderQuiz()}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    count: state.counter?.value,
});

const mapDispatchToProps = { storeLoggedInformation };

export default connect(mapStateToProps, mapDispatchToProps)(Assessment);
