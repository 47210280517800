import React from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import Question from "../components/Question";
import QuestionCount from "../components/QuestionCount";
import AnswerOption from "../components/AnswerOption";

function Quiz(props) {
    function renderAnswerOptions(key) {
        return <AnswerOption randomId={Math.floor(Math.random()*1000)} questionType={props.questionType} key={key.content} answerContent={key.content} answerType={key.type} answer={props.answer} questionId={props.questionId} onAnswerSelected={props.onAnswerSelected} />;
    }

    return (
        <CSSTransition className="container" component="div" transitionName="fade" transitionEnterTimeout={800} transitionLeaveTimeout={500} transitionAppear transitionAppearTimeout={500}>
            <div key={props.questionId}>
                <QuestionCount counter={props.questionId} total={props.questionTotal} />
                <Question content={props.question} />
                {props.associatedImages && props.associatedImages.length > 0}{" "}
                {
                    <div className="flex justify-content-center mb-3">
                        
                <hr />
                        {props.associatedImages.map((element) => {
                            return <img src={element} className="mr-2" style={{ width: "auto", height: "180px" }} />;
                        })}
                    </div>
                }
                <ul className="answerOptions">{props.answerOptions.map(renderAnswerOptions)}</ul>
            </div>
        </CSSTransition>
    );
}

Quiz.propTypes = {
    answer: PropTypes.string.isRequired,
    answerOptions: PropTypes.array.isRequired,
    question: PropTypes.string.isRequired,
    questionId: PropTypes.number.isRequired,
    questionTotal: PropTypes.number.isRequired,
    onAnswerSelected: PropTypes.func.isRequired,
};

export default Quiz;
