export const statusList = () => {
    return [
        {
            status: "Active",
        },
        {
            status: "InActive",
        },
    ];
};

export const batchStatusList = () => {
    return [
        {
            status: "Acceptance Pending",
        },
        {
            status: "Accepted",
        },
        {
            status: "Processed",
        },
        {
            status: "Completed",
        },
        {
            status: "Approval Pending",
        },
        {
            status: "Approved",
        },
        {
            status: "Closed",
        },
        {
            status: "Rejected",
        },
    ];
};

export const payoutStatusList = () => {
    return [
        {
            status: "Requested",
        },
        {
            status: "Declined",
        },
        {
            status: "Success",
        },
    ];
};

export const getExtension = (fileName) => {
    if (fileName) {
        const splittedFileName = fileName.split(".");
        return splittedFileName[1];
    }
};

export const convertAssessmentToRequired = async (quizQuestions) => {
    let newObject = [];
    if (quizQuestions && quizQuestions.length > 0) {
        quizQuestions.map((element, index) => {
            if (element.question_type === "Regular") {
                newObject.push({
                    question: element?.question_text,
                    questionType: element?.question_type,
                    answers: [
                        {
                            content: element?.option1,
                            type: "option1",
                        },
                        {
                            content: element?.option2,
                            type: "option2",
                        },
                    ],
                    correctAnswer: element?.answer_key,
                    associatedImages: element?.associated_image ? element?.optionsPath?.associated : [],
                });
                if (element?.option3) {
                    let object = { ...newObject[index] };
                    if (object["answers"]) {
                        object["answers"].push({ content: element?.option3, type: "option3" });
                    }
                }
                if (element?.option4) {
                    let object = { ...newObject[index] };
                    if (object["answers"]) {
                        object["answers"].push({ content: element?.option4, type: "option4" });
                    }
                }
                // return newObject;
            } else {
                newObject.push({
                    question: element?.question_text,
                    questionType: element?.question_type,
                    answers: [
                        {
                            content: element?.optionsPath?.option1,
                            type: "option1",
                        },
                        {
                            content: element?.optionsPath?.option1,
                            type: "option2",
                        },
                    ],
                    correctAnswer: element?.answer_key,
                    associatedImages: element?.associated_image ? element?.optionsPath?.associated : [],
                });
                if (element?.option3) {
                    let object = { ...newObject[index] };
                    if (object["answers"]) {
                        object["answers"].push({ content: element?.optionsPath?.option3, type: "option3" });
                    }
                }
                if (element?.option4) {
                    let object = { ...newObject[index] };
                    if (object["answers"]) {
                        object["answers"].push({ content: element?.optionsPath?.option4, type: "option4" });
                    }
                }
            }
        });
    }
    return newObject;
};
