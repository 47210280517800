import React from "react";
import { Button } from "primereact/button";

export const SuccessToastConfig = (detail) => {
    return {
        severity: "success",
        summary: "",
        detail: detail,
        life: 5000,
    };
};

export const ErrorToastConfig = (detail) => {
    return {
        severity: "error",
        summary: "",
        detail: detail,
        life: 5000,
    };
};

export const ConfirmToastConfig = ({ headerText, mainContent }, handleConfirm, handleCancel) => {
    return {
        severity: "default",
        sticky: true,
        content: (
            <div className="p-flex p-flex-column" style={{ flex: "1" }}>
                <div className="p-text-left">
                    {/* <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "3rem" }}
          ></i> */}
                    <h4>{headerText}</h4>
                    <p>{mainContent}</p>
                </div>

                <div className="p-fluid p-d-flex">
                    <div className="CancelButton">
                        <Button type="button" label="Discard" className="p-button-danger p-button-text" onClick={handleCancel} />
                    </div>
                    <div>
                        <Button type="button" label="Yes, Sure" className="p-button-outlined p-button-danger ConfirmButton" onClick={handleConfirm} />
                    </div>
                </div>
            </div>
        ),
    };
};
